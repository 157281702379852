import React, {useState} from 'react';
import {Link as RouterLink, LinkProps as RouterLinkProps} from 'react-router-dom';
import Slider, {Settings} from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../../src/slick-custom.css';
import { Typography, Box, Grid, CardContent, CardActions, Button, Link, LinkProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { globalColors } from '../../color';
import { proximaNovaBoldFont, proximaNovaRegularFont, globalFontSize, globalFontWeight } from '../../fonts';
import bgGrey from '../../images/bgGrey.svg';
import bgDarkOne from '../../images/bgDark.svg';
import bgAnimateOne from '../../images/bgAnimate.svg';
import bgDarkTwo from '../../images/bgDarkTwo.svg';
import bgAnimateTwo from '../../images/bgAnimateTwo.svg';
import bgDarkThree from '../../images/bgDarkThree.svg';
import bgAnimateThree from '../../images/bgAnimateThree.svg';
import bgDarkFour from '../../images/bgDarkFour.svg';
import bgAnimateFour from '../../images/financeIcon.svg';
import bgDarkFive from '../../images/bgDarkFive.svg';
import bgAnimateFive from '../../images/bgAnimateFive.svg';
import bgDarkSix from '../../images/bgDarkSix.svg';
import bgAnimateSix from '../../images/bgAnimateSix.svg';
import bgDots from '../../images/dots.svg';
import { ReactComponent as NextIcon } from '../../images/Group 136.svg';
import { ReactComponent as PrevIcon } from '../../images/Group 137.svg';
import { IconButton, useTheme } from '@mui/material';

const useStyles = makeStyles((theme)=>({
    image: {
        width: '100%'
    },
    sliderMainContent: {
        maxWidth: '60rem',
        margin: 'auto',
        position: 'relative',
        display: 'flex',
        height: '20rem',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',

    },
    sliderInner: {
        left: 0,
        top:0,
        bottom:0,
        position: 'absolute',
        width: '100%',

        
    },
      partnersHeading:{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
      }
    }))

interface CarouselItemsProps {
    title: string,
    description: string,
    icon: string,
    icontwo: string,
    iconthree: string,
    iconfour: string,
    buttonText: string,
    buttonUrl: string, 
    activeLink: string,

}

interface CustomArrowProps{
    className?:string;
    onClick?: ()=>void;
    style?:React.CSSProperties;
    currentSlide: number;
    slideCount: number;
    slidesToShow: number;
}

export const carouselItems: CarouselItemsProps[] = [
    {
        title: 'HR Use cases',
        description: 'GenAI boosts HR productivity and efficiency with the right communication tools, and data-driven insights for decision-making.',
        icon: bgGrey,
        icontwo: bgDarkSix,
        iconthree: bgAnimateSix,
        iconfour: bgDots,
        buttonText: 'Read more',
        buttonUrl: '/hr-usecase',
        activeLink: "use-cases",
    },
    {
        title: 'SALES',
        description: 'Boosting sales productivity with AI-driven insights and personalized support',
        icon: bgGrey,
        icontwo: bgDarkThree,
        iconthree: bgAnimateThree,
        iconfour: bgDots,
        buttonText: 'Read more',
        buttonUrl: '/sales',
        activeLink: "use-cases",
    },
    {
        title: 'Supply chain',
        description: 'GenAI optimizes supply chain with AI, data analytics, and logistics.',
        icon: bgGrey,
        icontwo: bgDarkFive,
        iconthree: bgAnimateFive,
        iconfour: bgDots,
        buttonText: 'Read more',
        buttonUrl: '/supply-chain',
        activeLink: "use-cases",
    },
    {
        title: 'Customer Experience',
        description: 'Driving customer loyalty through personalized experiences with Generative AI',
        icon: bgGrey,
        icontwo: bgDarkOne,
        iconthree: bgAnimateOne,
        iconfour: bgDots,
        buttonText: 'Read more',
        buttonUrl: '/customer-experience',
        activeLink: "use-cases"
    },
    {
        title: 'ESG',
        description: 'Advancing sustainability and social responsibility with Generative AI',
        icon: bgGrey,
        icontwo: bgDarkTwo,
        iconthree: bgAnimateTwo,
        iconfour: bgDots,
        buttonText: 'Read more',
        buttonUrl: '/esg',
        activeLink: "use-cases",
    },
    {
        title: 'Finance',
        description: 'GenAI revolutionizes finance by optimizing processes, providing insights, and enhancing decision-making for better outcomes.',
        icon: bgGrey,
        icontwo: bgDarkFour,
        iconthree: bgAnimateFour,
        iconfour: bgDots,
        buttonText: 'Read more',
        buttonUrl: '/finance',
        activeLink: "use-cases",
    },
]

const viewButton:CarouselItemsProps[] = [
    {
        title: 'All Use cases',
        description: '',
        icon: '',
        icontwo: '',
        iconthree: '',
        iconfour: '',
        buttonText: '',
        buttonUrl: '/all-usecases',
        activeLink: "all-usecases",
    },
]


const UseCases: React.FC = () => {
    const theme = useTheme();
    const [currentSlide, setCurrentSlide] = useState(0);

    const PrevArrow: React.FC<CustomArrowProps> = (props)=>{  
        const { className, onClick, style,  currentSlide } = props;
        const isDisabled = currentSlide === 0;
        console.log(`PREVARROW - currentSlide: ${currentSlide}, isDisabled: ${isDisabled}`)
        return (
            <IconButton sx=
            {{
                display: "block", top: '36%', position: 'absolute', padding: 0, 
                width:{xs:'2rem', sm:'2rem', md:'3rem', lg:'3rem', xl:'3rem'},
                left:{xs: '35px', sm:'-40px', md:'-70px', lg:'-70px', xl: '-70px'},
                '&:hover': {
                    backgroundColor: 'unset'
                }
            }}
                onClick={onClick} className={className} disabled={isDisabled}>
               { isDisabled ? <PrevIcon width="100%" /> : <NextIcon width="100%" style={{transform: 'rotateY(180deg)'}}/> }
            </IconButton>
        );
    };
    
    const NextArrow: React.FC<CustomArrowProps> =(props) => {
        const { className, onClick, currentSlide, style, slideCount, slidesToShow } = props;
        const isDisabled = currentSlide >= slideCount - slidesToShow;
        console.log(`Nextarrow - currentSlide: ${currentSlide}, isDisabled: ${isDisabled}`)
        return (
            <IconButton sx=
            {{
                display: "block", top: '36%', position: 'absolute', padding: 0, 
                width:{xs:'2rem', sm:'2rem', md:'3rem', lg:'3rem', xl:'3rem'},
                right:{xs: '30px', sm:'-30px', md:'-10px', lg:'-10px', xl: '-10px'},
                '&:hover': {
                    backgroundColor: 'unset'
                }
            }}
            onClick={onClick} className={className} disabled={isDisabled} 
            style={{cursor: isDisabled ? 'not-allowed': 'pointer'}}>
           { isDisabled ? <PrevIcon width="100%" style={{transform: 'rotateY(180deg)'}}/> : <NextIcon width="100%"  /> }
        </IconButton>
        );
    };

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        beforeChange:(current:number, next:number)=> {setCurrentSlide(next)},
        swipeToSlide: true,
        nextArrow: <NextArrow currentSlide={currentSlide} slideCount={6} slidesToShow={3}/>,
        prevArrow: <PrevArrow currentSlide={currentSlide} slideCount={6} slidesToShow={3}/>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    centerMode:false,
                    variableWidth:false,
                    nextArrow: <NextArrow currentSlide={currentSlide} slideCount={6} slidesToShow={3}/>,
                    prevArrow: <PrevArrow currentSlide={currentSlide} slideCount={6} slidesToShow={3}/>,
                },
            },
            {
                breakpoint: 767,
                settings:{
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    swipeToSlide: true,
                    centerMode:false,
                    variableWidth:false,
                }
            },
            {
                breakpoint: 459,
                settings:{
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    swipeToSlide: true,
                    centerMode:false,
                    variableWidth:false,
                    nextArrow: <NextArrow currentSlide={currentSlide} slideCount={6} slidesToShow={1}/>,
                    prevArrow: <PrevArrow currentSlide={currentSlide} slideCount={6} slidesToShow={1}/>,
                }
            },

            {
                breakpoint: 599,
                settings:{
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    swipeToSlide: true,
                    centerMode:false,
                    variableWidth:false,
                }
            },
            {
                breakpoint: 768,
                settings:{
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    swipeToSlide: true,
                    centerMode:false,
                    variableWidth:false,
                }
            }
        ],
    };
    
    const classes = useStyles();
    const title =  {
        color: globalColors.color_4,
        fontFamily: proximaNovaBoldFont.fontFamily,
        fontWeight: globalFontWeight.font_700,
        lineHeight: 'normal',
        fontSize: {xs:globalFontSize.font_28, sm:globalFontSize.font_28, md:globalFontSize.font_35, lg:globalFontSize.font_35, xl:globalFontSize.font_35},
        paddingBottom: { xs: '0.5rem', sm: '0.5rem', md: 'unset', lg: 'unset', xl: 'unset' }, 
      };
      const subHeading = {
        color: globalColors.color_10,
        fontFamily: proximaNovaRegularFont.fontFamily,
        fontWeight: globalFontWeight.font_400,
        lineHeight: 'normal',
        fontSize: {xs:globalFontSize.font_19, sm: globalFontSize.font_19, md:globalFontSize.font_20, lg: globalFontSize.font_20, xl:globalFontSize.font_20}
      };
      const usecaseOuterWrapper = {
        flexDirection: 'column', alignItems:'center',
        margin:{ xs: '4rem 0rem', sm: '4rem 4rem', md: '0rem 5rem', lg: 'auto', xl:'auto'},
        marginBottom: { xs: '0rem', sm: '0rem', md: '0rem', lg: '0rem', xl:'0rem'},
        width:{ xs: 'auto', sm: 'auto', md: 'initial', lg: 'initial', xl:'initial'},
      }

      const cardContent = {
           width: '13rem', 
            marginLeft: {xs:'0rem', sm:'0rem', md:'0.8rem', lg:'0.8rem', xl:'0.8rem'},
           marginTop: {xs:'0rem', sm:'0rem', md:'2rem', lg:'2rem', xl:'2rem'},
           padding: {xs:'1.5rem 1rem', sm:'1.5rem 1rem', md:'0rem', lg:'0rem', xl:'0rem'},
      };

      const cardTitle = {
            color: globalColors.color_15,
            textTransform: 'uppercase',
            lineHeight: 'normal',
            letterSpacing: {xs:'unset', sm:'unset', md:'0.07rem', lg:'0.07rem', xl:'0.07rem'},
            fontFamily: 'Proxima Nova Regular',
            fontWeight: globalFontWeight.font_400,
            fontSize: {xs: globalFontSize.font_14, sm: globalFontSize.font_14, md:globalFontSize.font10, lg: globalFontSize.font10, xl:globalFontSize.font10}
      };
      const cardDescription = {
            color: globalColors.color_8,
            lineHeight: 'normal',
            minHeight: '100px',
            fontFamily: 'Proxima Nova Regular',
            fontWeight: globalFontWeight.font_400,
            fontSize: {xs:globalFontSize.font_15, sm:globalFontSize.font_15, md:globalFontSize.font_15v2, lg:globalFontSize.font_15v2, xl:globalFontSize.font_15v2}
      };
    return (
          <>
           <Grid container id="use-cases" sx={usecaseOuterWrapper}>
           <Grid sx={{ 
            margin: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' },
            paddingBottom:{xs:'2rem', sm:'2rem', md:'3rem', lg:'3rem', xl:'3rem'}
           }} 
            xs={12} md={8} item className={classes.partnersHeading} flexDirection={'column'}>
                <Typography sx={title}>
                    <span>Use cases</span>
                </Typography>
                <Typography sx={subHeading} textAlign={'center'}>
                    <span>Explore the power of Generative AI across diverse scenarios!</span>
                </Typography>
            </Grid>
                <Box className={classes.sliderMainContent}>
                      <Slider {...settings} className={classes.sliderInner}>
                  {carouselItems.map((item, index) => (
                        <Grid item position={'relative'}>
                            <Box sx={{ position: 'absolute', top: '31px', right: '60px', width: '12rem', 
                            display:{xs:'none', sm:'none', md:'inherit', lg:'inherit', xl:'inherit'}}}>
                                <img src={item.iconfour} alt='' className={classes.image} />
                            </Box>
                            <Grid item position={'relative'}
                                sx={{ width:{xs:'13rem', sm:'13rem', md:'14rem', lg:'14rem', xl:'14rem'}, borderRadius: '0.5rem', backgroundColor: '#DBDDEF' }}>
                                <Box
                                    sx={{ width: '8rem', height: 'auto', paddingTop: '24px', marginLeft: '60px' }}>
                                    <img src={item.icontwo} alt="" className={classes.image} />
                                </Box>
                                <Box
                                    sx={{ width: '4.4rem', position: 'absolute', bottom: '12px', left: '22px', right: 0, margin: 'auto' }}>
                                    <img src={item.iconthree} alt='' className={classes.image} />
                                </Box>
                            </Grid>
                            <CardContent sx={cardContent}>
                                <Typography variant='h5' component="div" sx={cardTitle}>
                                    {item.title}
                                </Typography>
                                <Typography variant='body2' sx={cardDescription}>
                                    {item.description}
                                </Typography>
                                <CardActions sx={{padding: '0.5rem 0rem'}}>
                                    <Link component={RouterLink} to={{pathname:item.buttonUrl, state:{activeLink: item.activeLink}
                                } as RouterLinkProps["to"]}>
                                        {item.buttonText}
                                    </Link>
                                </CardActions>
                            </CardContent>
                        </Grid>
                    ))
                    }
                </Slider>
            </Box>
            {viewButton.map((item, index)=>(
                <Button variant='outlined' color="primary" component={RouterLink} to={{pathname:item.buttonUrl, state:{activeLink: item.activeLink}
            } as RouterLinkProps["to"]} sx={{
                 padding: '1rem 2rem',
                 gap: '0.375rem',
                 marginTop:'1rem',
                 fontSize: globalFontSize.font_12,
                 fontWeight: globalFontWeight.font_700,
                 borderRadius: '4.5rem',
                 background: '#3D216F',
                 color: '#fff',
                 lineHeight: '1rem',
                 textTransform: 'unset', 
                 '&:hover':{
                    background: '#3D216F',
                 }
            }}>
                    View all Use cases
               </Button>
               
            ))}
       </Grid>
        </>

    )
}



export default UseCases





