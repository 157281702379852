
export interface ContentItem {
    id: number,
    title_date: string,
    heading: string,
    author_title: string,
    author_designation: string,
    blog_title: string,
    description: string,
}
export const blogsListing: ContentItem[] = [
    {
        id: 1,
        title_date: "Lab45 AI Platform. Jan 12, 2024. 2 min read",
        heading: "Unveiling Lab45 AI Platform: A Journey of Innovation and Impact",
        author_title: "Authors:",
        author_designation: "Dinesh Chahlia, VP and Head of Engineering and Product, Wipro Lab45 Generative AI platform",
        blog_title: "Greetings to all our readers!",
        description: `<p style="padding-bottom:1rem">We are thrilled to introduce you to our latest innovation, Lab45 AI Platform, a product of six months of relentless effort, creativity, and collaboration by the Lab45 team. This platform is a testament to our commitment to harnessing the power of Generative AI to revolutionize productivity and efficiency across enterprises.</p>
                       <p style="padding-bottom:1rem">The journey of building the Platform has been nothing short of exhilarating. Over the past six months, our team has been deeply engaged in designing, developing, and refining this platform. We've faced challenges, celebrated victories, and learned a great deal along the way. The result is a platform that we are immensely proud of, and one that we believe will truly make a difference in the way businesses operate.<p>
                       <p style="padding-bottom:1rem">One of the most rewarding aspects of this journey has been witnessing the rapid user adoption of the Platform. We are delighted to share that we have already onboarded close to 5,000 users! All this while we were still in alpha and beta⋅ This incredible response from our users is a strong validation of the value that the Platform brings to the table.<p>
                       <p style="padding-bottom:1rem">The AI Platform is already making waves across various business functions. Our customized apps, Wipro Chat and Document Chat, are enhancing productivity in HR, sales, testing, and more. For instance, HR teams are leveraging the AI Platform to streamline processes, while sales teams are using it to generate insights and drive strategy. In the realm of testing, the AI Platform is being harnessed to ensure quality and efficiency. Further, given we provide API access, many platforms and apps within Wipro are already using our API 
                       and seeing some amazing results. Stay tuned for details on those! Like all good things a whole global team across various continents worked to make all this happen.<p>
                       <p style="padding-bottom:1rem">But this is just the beginning. We are continuously working on expanding the capabilities of the Platform, and we are excited about the potential it holds. We are confident that as more and more users experience the power of the Platform, its impact will only grow.<p>
                       <p style="padding-bottom:1rem">As we move forward, we promise to keep you updated on the latest developments and achievements of the Lab45 AI Platform. We have a lot more in store, and we can't wait to share it with you. So, stay tuned for more exciting news and updates!<p>`,
    }
]