import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { globalFontSize, globalFontWeight, proximaNovaBoldFont, proximaNovaRegularFont } from '../../fonts';
import { globalColors, globalBgColor } from '../../color';
import Settingsicon from '../../images/Settingsicon.svg';
import Buildicon from '../../images/Buildicon.svg';
import Insightsicon from '../../images/Insightsicon.svg';
import Growthicon from '../../images/Growthicon.svg';
import Multifoldicon from '../../images/Multifold Efficiency.svg';
import Enhancedicon from '../../images/Customer experience.svg';
import SaaSicon from '../../images/Saas.svg';
import ApiIcon from '../../images/API.svg';
import Tailoredicon from '../../images/LLM.svg';
import Securityicon from '../../images/Security.svg';
import Benefitsgroup from '../../images/Group 128.svg';
import Rocketicon from '../../images/rocket.svg';
import Mobilebenefitsicon from '../../images/mobileBenefits.svg';

interface Service {
    icon: string,
    title: string,
    description: string,
    id:number,
}

const coreBenefits: Service[] = [
    {
        id:1,
        icon: Settingsicon,
        title: "Quick GenAI App Building",
        description: "Transform ideas into AI-powered applications rapidly and efficiently with GenAI",
    },

    {
        id:2,
        icon: Buildicon,
        title: "Build Custom Solutions",
        description: "Effortlessly create custom AI solutions with GenAI's versatile platform APIs",
    },

    {
        id:3,
        icon: Insightsicon,
        title: "Strategic Decision Making",
        description: "Empower your business with data-driven insights for strategic decision making",
    },

    {
        id:4,
        icon: Growthicon,
        title: "Drive Business Growth",
        description: "Unlock your potential for exponential business growth with our innovative platform",
    },

    {
        id:5,
        icon: Multifoldicon,
        title: "Multifold Efficiency",
        description: "Experience remarkable gains in efficiency and productivity with GenAI powered innovative solutions",
    },

    {
        id:6,
        icon: Enhancedicon,
        title: "Enhanced Customer Experience",
        description: "Deliver exceptional customer satisfaction through our platform's personalized and seamless experiences",
    },
];

//Additional array
const additionalBenefits: Service[] = [
    {
        id:1,
        icon: SaaSicon,
        title: "SaaS for rapid Transformation",
        description: "",
    },
    {
        id:2,
        icon: ApiIcon,
        title: "API Integration with Systems",
        description: "",
    },
    {
        id:3,
        icon: Tailoredicon,
        title: "Tailored LLM, DL/ML Selection",
        description: "",
    },
    {
        id:4,
        icon: Securityicon,
        title: "Security Guardrails",
        description: "",
    },
]

const Benefits: React.FC = () => {
    const benefitsWrapper = {
        padding: 0,
        gap:{xs:'1.5rem', sm:'1.5rem', md:'2rem', lg:'2rem', xl:'2rem'},
        flexDirection:{xs:'column', sm:'column', md:'row', lg:'row', xl:'row'},
        display:'flex',
        flexWrap: 'wrap',
        marginTop:{xs:'4rem', sm:'4rem', md:'unset', lg:'unset', xl:'unset'}     
    };
    const benefitsTitle = {
        color: globalColors.color_8,
        fontFamily: proximaNovaBoldFont.fontFamily,
        fontWeight: globalFontWeight.font_700,
        lineHeight: 'normal',
        fontSize:{ xs:globalFontSize.font_15, sm:globalFontSize.font_15, md:globalFontSize.font_14, lg:globalFontSize.font_15, xl:globalFontSize.font_15},

    };
    const benefitsContent = {
        color: globalColors.color_7,
        fontFamily: proximaNovaRegularFont.fontFamily,
        fontWeight: globalFontWeight.font_400,

    };
    const bgInitial = {
        backgroundColor: globalBgColor.background_0,
    };
    const image = {
        width: '100%',
    };
    const title = {
        color: globalColors.color_4,
        fontFamily: proximaNovaBoldFont.fontFamily,
        fontWeight: 700,
        lineHeight: 'normal',
    };
    const subHeading = {
        color: '#8A8A8A',
        fontFamily: 'Proxima Nova Regular',
        fontWeight: 400,
        lineHeight: 'normal',
    };
    const textWrap = {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign:{xs: 'center', sm: 'center'},

    };
    const benefitsImageWrapper = {
        display:{xs:'none', sm:'none', md:'initial', lg:'initial', xl:'initial'},
        paddingTop:'20px',
    };
    const boxContentWrapper = {
        paddingBottom:{xs: '0rem', sm: '0rem', md: '2rem', lg: '2rem'}, 
        flexDirection:{xs: 'row', sm: 'row', md: 'row', lg: 'row', xl:'row'}, 
        gap:{xs:'0.5rem', sm:'0.5rem', md:'0.3rem', lg:'0.3rem'}, 
        textAlign:{xs: 'left', sm: 'left', md: 'left', lg: 'left', xl:'left'},
        minHeight:{xs:'auto', sm:'auto', md:'8rem', lg:'8rem', xl:'8rem'},
        width:{xs:'100%', sm:'100%', md:'44%', lg:'44%', xl:'44%'},
        paddingLeft:{xs:'0rem', sm:'0rem', md: '65px', lg: '56px', xl:'70px'},
    };
    const boxItem = {
        width: {xs: '70%', sm: '70%', md: '20%', lg: '20%', xl:'20%'}, 
        margin:{xs: 'unset', sm: 'unset'}, 
        maxWidth:'fit-content',
        height:{xs:'fit-content', sm:'fit-content', md:'initial', lg:'initial', xl:'initial'},
        position:{xs: 'relative', sm: 'relative', md: 'absolute', lg: 'absolute'}, 
        top:{xs:'0px', sm:'0px', md:'0px', lg:'0rem', xl:'0rem'},
    };
    return (
        <Box sx={{ margin:{xs:'4rem 1.3rem', sm: '2.5rem 1.3rem 4rem 1.3rem', md: '4.5rem 3rem', lg: '4.5rem 10rem', xl: '4.5rem 10rem', xxl:'4.5rem auto'} ,
        padding:{xs: '0px', sm: '0px', md: '0px', lg:'0px'},
        maxWidth:{md: '1366px', lg: '1366px', xl: '1366px'},
        }} 
        id="benefits">
            {/* heading */}
            <Grid item sx={textWrap}>
                <Typography style={title}
                sx={
                    {fontSize:{xs:globalFontSize.font_28, sm:globalFontSize.font_28, md:globalFontSize.font_35, lg:globalFontSize.font_35, xl:globalFontSize.font_35},
                    paddingBottom: { xs: '0.5rem', sm: '0.5rem', md: 'unset', lg: 'unset', xl: 'unset' }, 
                }} >
                    <span>Benefits & Features</span>
                </Typography>
                <Typography style={subHeading} textAlign={'center'} sx={{fontSize:{xs: globalFontSize.font_20, sm: globalFontSize.font_20, md: globalFontSize.font_20, lg: globalFontSize.font_20, xl: globalFontSize.font_20}
            }}>
                    <span >AI Agents: Power up your productivity</span>
                </Typography>
            </Grid>
            <Grid container sx={{ justifyContent: 'space-between', 
            marginTop:{xs: '0rem', sm:'0rem', md:'3rem', lg:'3rem'}}}>
                {/* left section */}

                  {/* mobile benefits icon */}
                <Grid item xs={12} md={5} position={'relative'} 
                sx={{display:{xs:'block', sm:'block', md:'none', lg:'none', xl:'none'}}}>
                    <Box sx={{
                        width:{xs:'initial', sm:'fit-content', md:'initial', lg:'initial', xl:'initial'},
                        margin:{xs:'initial', sm:'auto', md:'initial', lg:'initial', xl:'initial'},
                        position:{xs:'initial', sm:'relative', md:'initial', lg:'initial', xl:'initial'},
                        top:{xs:'initial', sm:'-24px', md:'initial', lg:'initial', xl:'initial'},
                        }}>
                     <img alt="" src={Mobilebenefitsicon} style={image}/>
                    </Box>
                </Grid>

                <Grid item xs={12} md={7} sx={benefitsWrapper}>
                    {coreBenefits.map((service, id) => (
                        <Box display={'flex'} position={'relative'} key={service.id} sx={boxContentWrapper}>
                            <Box left={'0rem'} sx={boxItem}>
                                <img alt={service.title} src={service.icon} style={image}/>
                            </Box>
                            <Box>
                                <Typography marginBottom={'0.5rem'} variant='h6' sx={benefitsTitle}>{service.title}</Typography>
                                <Typography variant='body2' style={benefitsContent} 
                                sx={{fontSize:{ xs:globalFontSize.font_15, sm:globalFontSize.font_15, md:globalFontSize.font_14, lg:globalFontSize.font_14, xl:globalFontSize.font_14, 
                                lineHeight:{xs:'1.5rem', sm:'1.5rem', md:'1rem', lg:'1rem', xl:'1rem'}}}}>{service.description}</Typography>
                            </Box>
                        </Box>
                    ))}
                </Grid>
                <Grid item xs={12} md={5} position={'relative'} sx={benefitsImageWrapper}>
                    <Box position={'absolute'} left={'-50px'} bottom={'10px'} width={'10rem'}>
                    <img alt="" src={Rocketicon} style={image}/>
                    </Box>
                    <img alt="" src={Benefitsgroup} style={image}/>
                </Grid>

               {/* right section */} 
               <Grid item xs={12} md={12} display={'flex'} 
               borderRadius={'5.7rem 0rem'} style={bgInitial} sx={{marginTop: '3rem', 
               padding:{xs:'3.5rem 2rem', sm:'3.5rem 2rem', md:'2rem 5rem', lg:'2rem 5rem', xl:'2rem 5rem'},
               flexDirection:{xs:'column', sm:'column', md:'row', lg:'row', xl:'row'},

               }}>
                  {additionalBenefits.map((service, index)=>(
                    <Box display={'flex'} alignItems={'center'} 
                    sx={{gap:{xs:'0.5rem', sm:'0.5rem', md:'0.5rem', lg:'0.5rem', xl:'0.5rem'},
                    }}>
                        <Box 
                        sx={{width:{xs:'fit-content', sm:'fit-content', md:'initial', lg:'initial', xl:'initial'},

                        }}>
                        <img alt={service.title} src={service.icon} style={image}/>
                        </Box>
                        <Box>
                        <Typography variant='h6' sx={benefitsTitle}>{service.title}</Typography>
                        </Box>
                    </Box>
                  ))}
               </Grid>
            </Grid>
        </Box>
    )
}
export default Benefits