import React from 'react';
import { Grid, Typography, Box, useTheme } from '@mui/material';
import Accordian from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles, styled } from '@mui/styles';
import { globalColors } from '../../color';
import { proximaNovaBoldFont, proximaNovaRegularFont, globalFontSize, globalFontWeight } from '../../fonts';

const useStyles = makeStyles({
    partnersHeading:{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
      },
      image: {
        width: '100%'
    },
});

const Faq: React.FC = () => {
    const theme = useTheme();
    const classes = useStyles();
    const title =  {
        color: globalColors.color_4,
        fontFamily: proximaNovaBoldFont.fontFamily,
        fontWeight: globalFontWeight.font_700,
        lineHeight: 'normal',
        fontSize: {
                xs: globalFontSize.font_28, 
                sm:globalFontSize.font_28, 
                md: globalFontSize.font_35, 
                lg:globalFontSize.font_35, 
                xl:globalFontSize.font_35,
        },
        paddingBottom: { xs: '0.5rem', sm: '0.5rem', md: 'unset', lg: 'unset', xl: 'unset' },
      };
      const content = {
        color: globalColors.color_7,
        fontFamily: proximaNovaRegularFont.fontFamily,
        fontSize: globalFontSize.font_14,
        fontWeight: globalFontWeight.font_400,
    };
    const subHeading = {
        color: globalColors.color_10,
        fontFamily: proximaNovaRegularFont.fontFamily,
        fontWeight: globalFontWeight.font_400,
        lineHeight: 'normal',
      };
    const heading = {
        color: globalColors.color_4,
        fontFamily: proximaNovaBoldFont.fontFamily,
        fontWeight: globalFontWeight.font_700,
        lineHeight: 'normal',
        fontSize: {xs:globalFontSize.font_18, 
            sm:globalFontSize.font_18,
            md: globalFontSize.font_19,
            lg: globalFontSize.font_19,
            xl: globalFontSize.font_19,
            } 
    }

    const [expanded, setExpanded] = React.useState<string | false>(false);
    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const panelContents = [
        {
            id: "panel1",
            header: "How does the Wipro Lab45 AI Platform work?",
            detail: `Wipro's Lab45 AI Platform is a versatile Generative AI platform that is designed to boost productivity and efficiency for businesses in different sectors. It is equipped with pre-built applications and skills to expedite the implementation of GenAI in various industry and domain functions. Enterprises can utilize GenAI powered applications, create agents for intricate problem-solving, and leverage APIs to develop customized solutions.
                     Additionally, the platform features a flexible, modular infrastructure that allows for smooth integration of underlying models and data sources.`
        },
        {
            id: "panel2",
            header: "How do I enable this for my enterprise?",
            detail: "Discover the power of our solutions for your organization! Reach out to us today and schedule a captivating demo. Find us on the Azure Marketplace and explore our innovative offerings. <a href='https://azuremarketplace.microsoft.com/en-us/marketplace/apps/wipro_lab45_aiteam.wipro-ai360platform?tab=Overview' target='_blank'>Click here</a> to uncover the magic of our solutions on Azure Marketplace!"
        },
        {
            id: "panel3",
            header: "What pre-built applications and skills does the GenAI platform offer?",
            detail: "The GenAI platform from Wipro offers a diverse range of pre-built applications and skills tailored to various industry and domain functions. These include Customer Service, HR Assist, Marketing, BGV, Helpdesk, SDLC, and Operational Technology, among others."
        },
        {
            id: "panel4",
            header: "Can I build custom solutions?",
            detail: "With Wipro's Lab45 AI Platform, business users have the opportunity to harness the full potential of GenAI for their specific use cases by developing custom solutions through APIs that align with their unique business requirements. Additionally, they can seamlessly integrate GenAI capabilities with existing applications and data sources, enabling on-the-go utilization of GenAI."
        }
    ]
    return (
        <Grid container sx={{
            margin: 
            {xs: '4rem 1.3rem', sm: '4rem 1.3rem', md: '3rem', lg: '0rem 10rem', xl: '0rem 10rem', xxl:'auto'}, 
            width: {xs:'auto', sm:'auto', md: 'auto', lg: 'auto'},
            maxWidth:{xl: '1366px'},
            position:'relative',
        }} id="faqs" spacing={3}>
            <Grid sx={{margin: {xs: '0rem', sm: '0rem', md: 'auto', lg: 'auto'}}}  xs={12} md={8} item className={classes.partnersHeading} flexDirection={'column'} style={{padding: '0rem'}}>
                <Typography sx={title}>
                    <span>Frequently Asked Questions</span>
                </Typography>
                <Typography style={subHeading} textAlign={'center'} sx={{
                    fontSize:
                    {xs:globalFontSize.font_19, 
                        sm: globalFontSize.font_19, 
                        md:globalFontSize.font_20, 
                        lg: globalFontSize.font_20, 
                        xl:globalFontSize.font_20
                    }
                }}>
                    <span>Here’s some frequently asked questions about Lab45 AI Platform</span>
                </Typography>
            </Grid>
            <Box sx={{margin: {xs: '0rem', sm: '0rem', md: 'auto', lg: '3rem 0rem'}}}>
                {panelContents.map((panel) => (
                    <Accordian
                        key={panel.id} expanded={expanded === panel.id}
                        onChange={handleChange(panel.id)} sx={{boxShadow: 'none', backgroundColor:'unset', zIndex:'11', '&:before':{display: 'none'}, 
                        borderBottom: '2px solid #DCE2F8'}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{minHeight: '5rem'}}>
                            <Typography sx={heading} style={{color: expanded === panel.id ? globalColors.color_11 : ''}}>{panel.header}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={content} sx={{lineHeight: '1.4rem'}} dangerouslySetInnerHTML={{__html:panel.detail}}></Typography>
                        </AccordionDetails>
                    </Accordian>
                ))}
            </Box>
        </Grid>
    )
}
export default Faq
