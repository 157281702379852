import React from 'react';
import { Card, CardContent, Box, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { globalFontSize, globalFontWeight } from '../../fonts';
import { globalColors } from '../../color';
import Pattern from '../../images/pattern.svg';
const useStyles = makeStyles({
    contentInnerWrap: {
        justifyContent: 'center',
        marginTop: '60px',
    },
    heading2: {
        color: globalColors.color_3,
    },
    image: {
        width: '100%'
    }

});

interface CardProps {
    number: string;
    label: string;
}

const GenAICard: React.FC = () => {
    const boxCustom = {
        border: '1px solid #F4EBFF',
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(180deg, #FAFAFF 0%, #FAF6FF 100%)',
        boxShadow: '0px 0px 34px 0px #FAEDFF',
        borderRadius: '1.75rem',
    };

    const heading = {
        color: globalColors.color_2,
        fontFamily: 'Proxima Nova Bold',
        fontWeight: globalFontWeight.font_700,
    };

    const bodyText = {
        fontFamily: 'Proxima Nova Regular',
        fontWeight: globalFontWeight.font_400,
        fontSize: globalFontSize.font_15,
    };

    const description = {
        fontFamily: 'Proxima Nova Bold',
        fontWeight: globalFontWeight.font_700,
    };
    const patternImg = {
        top: '-50px',
        width: '13rem',
        height: '13rem',
    };
    const label = {
        color: '#6C7197',
        textAlign: 'center',
        fontFamily: 'Proxima Nova Regular',
        fontSize: globalFontSize.font_15v2,
    };
    const boxNumeric = {
        fontSize: { xs: globalFontSize.font_27, sm: globalFontSize.font_27, md: globalFontSize.font_40, lg: globalFontSize.font_40, xl: globalFontSize.font_40 },
        fontFamily: 'Proxima Nova Bold',
        fontWeight: globalFontWeight.font_700,
        lineHeight: 'normal',
    };
    const line = {
        width: '35%',
        height: 2,
        backgroundColor: '#1C6DFF',
        margin: '4px auto',
    };
    const p0 = {
        padding: '0rem'
    };

    const cardwrapper = {
        gap: { xs: '20px', sm: '20px', md: '45px', lg: '45px', xl: '45px' },
        justifyContent: { xs: 'space-between', sm: 'space-between', md: 'center', lg: 'center', xl: 'center' },
        margin: { xs: '0 1.3rem 4rem 1.3rem', sm: '0 1.3rem 4rem 1.3rem', md: '4.5rem 3rem 4.5rem 3rem', lg: '4.5rem 9rem 4.5rem 9rem', xl: '9rem 9rem 4.5rem 9rem' },
        flexDirection: { xs: 'row', sm: 'row', md: 'initial', lg: 'initial', xl: 'initial' },
        flexWrap: { xs: 'wrap', sm: 'wrap', md: 'initial', lg: 'initial', xl: 'initial' },
        alignItems: 'center',
        display: 'flex',
        maxWidth: { xs: 'unset', sm: 'unset', md: 'unset', lg: '1366px', xl: '1366px', xxl:'100%'},

    };

    const card = {
        width: { xs: '46%', sm: '46%', md: '10rem', lg: '10rem', xl: '12rem' },
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '1rem',
        padding: '1.3rem 0rem',
    };

    const content = {
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: '0', sm: '0', md: '1rem', lg: '1rem', xl: '1rem' },
        textAlign: 'center',
    };

    const classes = useStyles();
    return (
        <>
            <Box sx={cardwrapper} >
                <Card sx={card} style={boxCustom}>
                    <CardContent sx={content} style={p0}>
                        <Box>
                            <Typography sx={boxNumeric} component="span" color={globalColors.color_4}>6</Typography>
                            <Typography sx={boxNumeric} component="span" color={globalColors.color_11}>+</Typography>
                        </Box>
                        <Box>
                            <Typography style={line}></Typography>
                        </Box>
                        <Box>
                            <Typography sx={label}>Leading LLMs</Typography>
                        </Box>
                    </CardContent>
                </Card>
                <Card sx={card} style={boxCustom}>
                    <CardContent sx={content} style={p0}>
                        <Box>
                            <Typography sx={boxNumeric} component="span" color={globalColors.color_4}>1Bn</Typography>
                            <Typography sx={boxNumeric} component="span" color={globalColors.color_6}>+</Typography>
                        </Box>
                        <Box>
                            <Typography style={line}></Typography>
                        </Box>
                        <Box>
                            <Typography sx={label}>Tokens Served</Typography>
                        </Box>
                    </CardContent>
                </Card>
                <Card sx={card} style={boxCustom}>
                    <CardContent sx={content} style={p0}>
                        <Box>
                            <Typography sx={boxNumeric} component="span" color={globalColors.color_4}>100</Typography>
                            <Typography sx={boxNumeric} component="span" color={globalColors.color_12}>k+</Typography>
                        </Box>
                        <Box>
                            <Typography style={line}></Typography>
                        </Box>
                        <Box>
                            <Typography sx={label}>Enterprise Users</Typography>
                        </Box>
                    </CardContent>
                </Card>
                <Card sx={card} style={boxCustom}>
                    <CardContent sx={content} style={p0}>
                        <Box>
                            <Typography sx={boxNumeric} component="span" color={globalColors.color_4}>110</Typography>
                            <Typography sx={boxNumeric} component="span" color={globalColors.color_13}>k</Typography>
                        </Box>
                        <Box>
                            <Typography style={line}></Typography>
                        </Box>
                        <Box>
                            <Typography sx={label}>Hours Saved</Typography>
                        </Box>
                    </CardContent>
                </Card>
                <Card sx={card} style={boxCustom}>
                    <CardContent sx={content} style={p0}>
                        <Box>
                            <Typography sx={boxNumeric} component="span" color={globalColors.color_4}>+30</Typography>
                            <Typography sx={boxNumeric} component="span" color={globalColors.color_14}>%</Typography>
                        </Box>
                        <Box>
                            <Typography style={line}></Typography>
                        </Box>
                        <Box>
                            <Typography sx={label}>Productivity Gains</Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Box>

            <Box display={'flex'} sx={{
                width: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' },
                margin: { xs: 'auto', sm: 'auto', md: '0rem 0rem', lg: '0rem 3rem', xl: 'auto' },
                padding: { xs: '1.5rem', sm: '1.5rem', md: 'unset', lg: 'unset' },
                maxWidth: { md: '1366px', lg: '1366px' }
            }}>
                <Grid container alignItems="center" className={classes.contentInnerWrap}
                    sx={{ position: 'relative', alignItems: 'flex-start', marginTop: { xs: '0rem', sm: '0rem', md: '3.75rem', lg: '3.75rem' } }}>
                    <Grid container sx={{
                        position: 'absolute',
                        left: { xs: '0px', sm: '0px', md: '120px', lg: '120px', xl: '120px' }
                    }} style={patternImg}>
                        <img src={Pattern} alt="" className={classes.image} />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{
                        paddingLeft: { xs: '0rem', sm: '0rem', md: '0rem', lg: '5rem' },
                        paddingBottom: { xs: '2rem', sm: '2rem', md: 'unset', lg: 'unset' }
                    }}>
                        <Typography variant='h3' component="h1" style={heading}
                            sx={{ fontSize: { xs: globalFontSize.font_32, sm: globalFontSize.font_32, md: globalFontSize.font_38, lg: globalFontSize.font_38, xl: globalFontSize.font_38 } }}>
                            Our Powerful
                        </Typography>
                        <Typography variant='h3' component="h1" color="primary"
                            style={heading} sx={{ fontSize: { xs: globalFontSize.font_32, sm: globalFontSize.font_32, md: globalFontSize.font_38, lg: globalFontSize.font_38, xl: globalFontSize.font_38 } }}>
                            GenAI <span className={classes.heading2}>Solutions</span>
                        </Typography>
                        <Typography variant='body1' style={bodyText} color={globalColors.color_2}>
                            Accelerate GenAI Adoption with Apps, Agents, and APIs
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ paddingLeft: { xs: '0rem', sm: '0rem', md: '4rem', lg: '4rem' } }}>
                        <Box>
                            <Typography component="span" style={description} color={globalColors.color_4}
                                sx={{ fontSize: { xs: globalFontSize.font_19, sm: globalFontSize.font_19, md: globalFontSize.font_20, lg: globalFontSize.font_20, xl: globalFontSize.font_20 } }}>
                                Your key to
                            </Typography>
                            <Typography component="span" style={description} color={globalColors.color_5}
                                sx={{ fontSize: { xs: globalFontSize.font_19, sm: globalFontSize.font_19, md: globalFontSize.font_20, lg: globalFontSize.font_20, xl: globalFontSize.font_20 } }}> market</Typography>
                            <Typography component="span" style={description} color={globalColors.color_6}
                                sx={{ fontSize: { xs: globalFontSize.font_19, sm: globalFontSize.font_19, md: globalFontSize.font_20, lg: globalFontSize.font_20, xl: globalFontSize.font_20 } }}> adaptability</Typography>
                        </Box>

                        <Typography component="span" style={bodyText} color={globalColors.color_7}>
                            Elevating every department and process to new levels of efficiency and
                            innovation in your organization
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
export default GenAICard