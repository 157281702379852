import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { globalColors } from '../../color';
import { proximaNovaBoldFont, proximaNovaRegularFont, globalFontSize, globalFontWeight } from '../../fonts';

const useStyles = makeStyles({
    partnersHeading:{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }
});
const ContactUs: React.FC = ()=>{
    const subHeading = {
      color: '#8A8A8A',
      fontFamily: proximaNovaRegularFont.fontFamily,
      lineHeight: 'normal',
    };
    const title = {
      color: globalColors.color_4,
      fontFamily: proximaNovaBoldFont.fontFamily,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
    };
    const buttonCommon = {
      padding: '1rem 2rem',
      gap: '0.375rem',
      fontWeight: globalFontWeight.font_700,
      borderRadius: '4.5rem',
      background: '#3D216F',
      color: '#fff',
      lineHeight: '1rem',  
      fontSize: globalFontSize.font_12,
    }
    const contactOuterWrapper = {
        flexDirection:{xs:'column', sm:'column', md:'column', lg:'column', xl:'column'}, 
        marginTop:{xs: '0rem', sm:'0rem', md: '4.5rem', lg: '4.5rem'},  
        marginBottom:{xs: '0rem', sm:'0rem', md: '4.5rem', lg: '4.5rem'},
        margin:{xs: '0', sm: '0', md: 'initial', lg: 'initial', xl: 'initial'}, 
        width:{xs: 'auto', sm: 'auto', md:'initial', lg: 'initial', xl: 'initial'},
        textAlign: {xs:'center', sm:'center', md:'initial', lg:'initial', xl:'initial'},
        background:{xs:'radial-gradient(58.55% 127.82% at 50% 50%, rgba(255, 255, 255, 0.30) 0%, #F3F1FF 100%);', sm:'radial-gradient(58.55% 127.82% at 50% 50%, rgba(255, 255, 255, 0.30) 0%, #F3F1FF 100%);', md:'radial-gradient(58.55% 127.82% at 50% 50%, rgba(255, 255, 255, 0.30) 0%, #F3F1FF 100%);', lg:'radial-gradient(58.55% 127.82% at 50% 50%, rgba(255, 255, 255, 0.30) 0%, #F3F1FF 100%);', xl:'radial-gradient(58.55% 127.82% at 50% 50%, rgba(255, 255, 255, 0.30) 0%, #F3F1FF 100%);'},
        padding:{xs: '2rem 1.3rem', sm: '2rem 1.3rem', md: '2rem 0rem', lg:'2rem 0rem', xl:'2rem 0rem'},
    };
    const classes = useStyles();
    return (
        <Grid container id="contact-us" sx={contactOuterWrapper}>
            <Grid item className={classes.partnersHeading} sx={{flexDirection: 'column'}}>
        <Typography style={title} sx={{
          fontSize: {
            xs: globalFontSize.font_28, 
            sm:globalFontSize.font_28, 
            md: globalFontSize.font_35, 
            lg:globalFontSize.font_35, 
            xl:globalFontSize.font_35,
          }
        }}>
          <span>Contact Us</span>
        </Typography>
        <Typography sx={{textAlign: 'center', fontWeight:globalFontWeight.font_400, 
        fontSize:{xs:globalFontSize.font_15, sm:globalFontSize.font_15, md:globalFontSize.font_20, lg:globalFontSize.font_20, xl:globalFontSize.font_20}}} style={subHeading}>
          <span >Reach out to us today for an impactful demo session!</span>
        </Typography>
        <Button component="a" href='mailto:sales.ai360@wipro.com' variant='outlined' style={buttonCommon} sx={{
                fontSize: globalFontSize.font_12,
                textTransform: 'unset',  
                 '&:hover':{
                    background: '#3D216F',
                 },
                 marginTop:{xs:'1rem', sm:'1rem', md:'2rem', lg:'2rem', xl:'2rem'},
            }}>
           sales.ai360@wipro.com
        </Button>
      </Grid>
        </Grid>
    )
}

export default ContactUs