import React, { ReactNode } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, useMediaQuery } from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import customTheme from './createTheme';
import theme from './theme';
import CustomerExperience from './components/usecases/customer-experience/CustomerExperience';
import Home from './components/home/home';
import Esg from './components/usecases/esg/Esg';
import Sales from './components/usecases/sales/Sales';
import Finance from './components/usecases/finance/Finance';
import SupplyChain from './components/usecases/supply-chain/SupplyChain';
import HRUsecase from './components/usecases/hr-usecase/HrUsecase';
import { ActiveLinkProvider } from './ActiveLinkContext';
import AllUseCases from './components/usecases/AllUseCases';
import HrUsecase from './components/usecases/hr-usecase/HrUsecase';
import ScrollToTop from './components/ScrollToTop';
import Blogs from './components/blogs/Blogs';

  const App: React.FC = ()=>{
    const isXXL = useMediaQuery(customTheme.breakpoints.up('xxl'))
    return (
      <ActiveLinkProvider>
         <ThemeProvider theme={customTheme}>
     <Router>
       <div className='App'>
          <CssBaseline/>
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/customer-experience' element={<CustomerExperience/>}/>
            <Route path='/esg' element={<Esg/>}/>
            <Route path='/finance' element={<Finance/>}/>
            <Route path='/hr-usecase' element={<HrUsecase/>}/>
            <Route path='/all-usecases' element={<AllUseCases/>}/>
            <Route path='/sales' element={<Sales/>}/>
            <Route path='/supply-chain' element={<SupplyChain/>}/>
            <Route path='/blogs' element={<Blogs/>}/>
          </Routes>
      </div>
     </Router>
     <ScrollToTop/>
     </ThemeProvider> 
      </ActiveLinkProvider>
    )
  }
  

export default App;
