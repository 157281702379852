import React, {createContext, useState, useContext, ReactNode} from 'react';

interface ActiveLinkContextProps{
    activeLink: string;
    setActiveLink: (link:string)=> void;
}

const ActiveLinkContext = createContext<ActiveLinkContextProps | undefined>(
    undefined
);

export const useActiveLink =()=>{
    const context = useContext(ActiveLinkContext);
    if (!context) {
        throw new Error("useactivelink must be used within an activelink provider");
    }
    return context;
};

export const ActiveLinkProvider: React.FC<{children: ReactNode}> = ({
    children,
})=>{
    const [activeLink, setActiveLink] = useState<string>("");
    return (
        <ActiveLinkContext.Provider value={{activeLink, setActiveLink}}>
            {children}
        </ActiveLinkContext.Provider>
    );
};