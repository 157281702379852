import React from 'react';
import { Box } from '@mui/material';
import maskLogo from '../../images/Mask group.svg';
import mobileMaskLogo from '../../images/mobileMask.svg';

const MaskLogo: React.FC = ()=>{
    const imageMaxPropotion = {
        width: '100%',  
    }
    return (
        <Box sx={{display: 'flex'}}>
             <Box sx={{display:{xs:'none', sm:'none', md:'block', lg:'block', xl:'block'}, width:{xxl:'100%'}}}>
             <img src={maskLogo} alt='Mask' style={imageMaxPropotion}/>
             </Box>
             <Box sx={{display:{xs:'block', sm:'block', md:'none', lg:'none', xl:'none'},
             width:{xs:'100%', sm:'100%'}
            }}>
               <img src={mobileMaskLogo} alt='Mask' style={imageMaxPropotion}/>
             </Box>
        </Box>
    )
}

export default MaskLogo