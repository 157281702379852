import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useActiveLink } from '../../ActiveLinkContext';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Typography, Box, Grid, Card, CardContent, CardActions, Button, Link, LinkProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { globalColors } from '../../color';
import { styled } from '@mui/material/styles';
import { proximaNovaBoldFont, proximaNovaRegularFont, globalFontSize, globalFontWeight, proximaNovaAltLightFont } from '../../fonts';
import circularRing from '../../images/big circular.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import bgGrey from '../../images/bgGrey.svg';
import bgDarkOne from '../../images/bgDark.svg';
import bgAnimateOne from '../../images/bgAnimate.svg';
import bgDarkTwo from '../../images/bgDarkTwo.svg';
import bgAnimateTwo from '../../images/bgAnimateTwo.svg';
import bgDarkThree from '../../images/bgDarkThree.svg';
import bgAnimateThree from '../../images/bgAnimateThree.svg';
import bgDots from '../../images/dots.svg';
import Bgusecases from '../../images/banner.svg';
import Header from '../home/Header';
import Footer from '../Footer';
import {carouselItems} from '../../components/home/UseCases';
import ContactUs from '../home/ContactUs';
const useStyles = makeStyles({
    image: {
        width: '100%'
    },
    sliderMainContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    partnersHeading: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
    },
});

interface CarouselItemsProps {
    title: string,
    description: string,
    icon: string,
    icontwo: string,
    iconthree: string,
    iconfour: string,
    buttonText: string,
    buttonUrl: string,
    activeLink: string,

}


const AllUseCases: React.FC = () => {

    const location  = useLocation();
    const {setActiveLink} = useActiveLink();

    useEffect(()=>{
        if (location.pathname === "/all-usecases") {
            window.scroll({top:0, behavior:'smooth'})
            setActiveLink("use-cases")
        }else{
            setActiveLink("all-usecases")
        }
    }, [location.pathname, setActiveLink])
    
    const classes = useStyles();
    const title = {
        color: globalColors.color_4,
        fontFamily: proximaNovaAltLightFont.fontFamily,
        fontWeight: globalFontWeight.font_300,
    };
    const subHeading = {
        color: globalColors.color_2,
        fontFamily: proximaNovaRegularFont.fontFamily,
        fontWeight: globalFontWeight.font_400,
        letterSpacing: 'unset',
        lineHeight: '1.71rem',
    };

    const absImage = {
        width: '100%',
        backgroundSize:{xs:'cover', sm:'cover', md:'cover', lg:'cover', xl:'cover'},
        backgroundRepeat: 'no-repeat',
        backgroundPosition: {xs:'top center', sm:'top center', md:'center', lg:'center', xl:'center'},
        backgroundImage: `url(${Bgusecases})`,
        height:{xs:'200px', sm:'200px', md:'100vh', lg:'100vh', xl:'100vh'}
    };
    const abc = {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    };

    return (
        <>
        <Box sx={abc}>
            <Header />
            <Grid container id="all-usecases" 
            sx={{ height:{xs: '100vh', sm:'100vh', md:'calc(100vh + 28rem)', lg: 'calc(100vh + 28rem)', xl:'calc(100vh + 28rem)'},
            flex: '1',
            }}>

               <Grid sx={{ margin: { xs: '0rem', sm: '0rem', md: 'auto', lg: 'auto'},
                    paddingBottom:{xs:'0rem', sm:'0rem', md:'0rem', lg:'0rem', xl:'0rem'},
                    width: "100%",
                    position:'relative'
                }}
                    item className={classes.partnersHeading} flexDirection={'column'}>
                    <Box sx={{position:'absolute', zIndex:'111',
                        top:{xs:'60px', sm:'60px', md:'6rem', lg:'6rem', xl:'6rem'},
                        bottom:{xs:'0', sm:0, md:'unset', lg:'unset', xl:'unset'},
                        margin:{xs:'auto', sm:'auto', md:'unset', lg:'unset', xl:'unset'},
                        height:{xs:'100%', sm:'100%', md:'unset', lg:'unset', xl:'unset'}
                    }}>
                    <Typography style={title} 
                        sx={{ fontSize: {xs:globalFontSize.font_32, sm:globalFontSize.font_32, md:globalFontSize.font_48, lg:globalFontSize.font_48, xl:globalFontSize.font_48},
                        }} >
                            <span>Use cases</span>
                        </Typography>
                        <Typography style={subHeading} textAlign={'center'}
                        sx={{fontSize:{xs:globalFontSize.font_15, sm:globalFontSize.font_15, md:globalFontSize.font_17, lg:globalFontSize.font_17, xl:globalFontSize.font_17}}}>
                            <span>We help leading organizations cut through complexity and deliver results.</span>
                        </Typography>
                    </Box>
                        <Box sx={absImage}></Box>
                    </Grid>

                <Grid item sx={{
                    maxWidth: '1366px',
                    position: { xs: 'relative', sm: 'relative', md: 'relative', lg: 'relative', xl: 'relative' },
                    top: { xs: 'unset', sm: 'unset', md: '-11rem', lg: '-19rem', xl: '-19rem' },
                    margin:{xs:'0rem 1.3rem', sm:'0rem 1.3rem', md:'auto', lg:'auto', xl:'auto'},
                    width:{xs:'100%', sm:'100%', md:'unset', lg:'unset', xl:'unset'},
                    marginTop:{xs:'2rem', sm:'2rem', md:'unset', lg:'unset', xl:'unset'},
                }}>
                    
                    <Box className={classes.sliderMainContent} sx={{ 
                    flexWrap: 'wrap', 
                    justifyContent:{xs:'flex-start', sm:'flex-start', md:'center', lg:'center', xl:'center'},
                    gap: '0rem 3rem', 
                    margin:{xs:'0rem', sm:'0rem', md:'0rem 3rem', lg:'0rem 10rem', xl:'0rem 10rem'} 
                    }}>
                        {carouselItems.map((item, index) => (
                            <Grid item position={'relative'}>
                                <Box sx={{
                                    position: 'absolute', top: '96px', right: '-36px', width: '12rem',
                                    display: { xs: 'none', sm: 'none', md: 'inherit', lg: 'inherit', xl: 'inherit' }
                                }}>
                                    <img src={item.iconfour} alt='' className={classes.image} />
                                </Box>

                                <Card sx={{ boxShadow: 'none', backgroundColor: 'unset' }}>

                                    <Grid item position={'relative'}
                                        sx={{ width: '14rem', borderRadius: '0.5rem', backgroundColor: '#DBDDEF' }}>
                                        <Box
                                            sx={{ width: '8rem', height: 'auto', paddingTop: '24px', marginLeft: '60px', margin:{xs:'auto', sm:'auto', md:'60px', lg:'60px', xl:'60px'} }}>
                                            <img src={item.icontwo} alt="" className={classes.image} />
                                        </Box>
                                        <Box
                                            sx={{ width: '4.4rem', position: 'absolute', bottom: '12px', left: '22px', right: 0, margin: 'auto' }}>
                                            <img src={item.iconthree} alt='' className={classes.image} />
                                        </Box>
                                    </Grid>
                                    <CardContent sx={{
                                        width: {xs:'100%', sm:'100%', md:'13rem', lg:'13rem', xl:'13rem'},
                                        minHeight:{xs:'unset', sm:'unset', md:'unset', lg:'unset', xl:'unset'},
                                        marginLeft: { xs: '0rem', sm: '0rem', md: '0.8rem', lg: '0.8rem', xl: '0.8rem' },
                                        marginTop: { xs: '0rem', sm: '0rem', md: '2rem', lg: '2rem', xl: '2rem' },
                                        padding: { xs: '1.5rem 1rem', sm: '1.5rem 1rem', md: '0rem', lg: '0rem', xl: '0rem' },
                                    }}>
                                        <Typography variant='h5' component="div" sx={{
                                            color: globalColors.color_15,
                                            textTransform: 'uppercase',
                                            lineHeight: 'normal',
                                            letterSpacing: { xs: 'unset', sm: 'unset', md: '0.07rem', lg: '0.07rem', xl: '0.07rem' },
                                            fontFamily: 'Proxima Nova Regular',
                                            fontWeight: globalFontWeight.font_400,
                                            fontSize: { xs: globalFontSize.font_14, sm: globalFontSize.font_14, md: globalFontSize.font10, lg: globalFontSize.font10, xl: globalFontSize.font10 }
                                        }}>
                                            {item.title}
                                        </Typography>
                                        <Typography variant='body2' sx={{
                                            color: globalColors.color_8,
                                            lineHeight: 'normal',
                                            minHeight: {xs:'auto', sm:'auto', md:'100px', lg:'100px', xl:'100px'},
                                            fontFamily: 'Proxima Nova Regular',
                                            fontWeight: globalFontWeight.font_400,
                                            fontSize: {xs:globalFontSize.font_15, sm:globalFontSize.font_15, md:globalFontSize.font_15v2, lg:globalFontSize.font_15v2, xl:globalFontSize.font_15v2}
                                        }}>
                                            {item.description}
                                        </Typography>
                                        <CardActions sx={{ padding: '0.5rem 0rem' }}>
                                            <Link component={RouterLink} to={{
                                                pathname: item.buttonUrl, state: { activeLink: item.activeLink }
                                            } as RouterLinkProps["to"]} onClick={() => console.log(`navigate to ${item.buttonUrl} with active link ${item.activeLink}`)} >
                                                {item.buttonText}
                                            </Link>
                                        </CardActions>
                                    </CardContent>

                                </Card>

                            </Grid>
                        ))
                        }
                    </Box>
                </Grid>
            </Grid>
            <ContactUs/>
        </Box>

        <Footer />
        </>

    )
}



export default AllUseCases





