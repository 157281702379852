import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { proximaNovaBoldFont } from '../../fonts';
import GenAICard from './GenAICard';
import GenAIDesktop from '../../images/GenAI_Integration.svg';
import GenAImob from '../../images/GenAI-Integration-mobile.svg';
const useStyles = makeStyles({
    heading: {
        fontFamily: proximaNovaBoldFont.fontFamily,
        fontSize: '2.2rem',
        fontStyle: 'normal',
        color: '#1F1C61',
        fontWeight: 700,
        lineHeight: 'normal',
    },
    image: {
        width: "100%",
    },

    imgWrapper: {
        position: 'relative',
        height: '100%',
    },
    imgWrapperInner: {
        width: '650px',
        position: 'absolute',
    },
    genaiGradient: {
        position: 'absolute',
        left: 0,
        right: 0,
        margin: 'auto',
        width: '28rem',
        opacity: 0.1,
    }

});

interface IconData {
    id: number,
    icon: string,
    left: string,
    top: string
}

const GenAI: React.FC = () => {
    const classes = useStyles();
    const GenAImobImage = {
        width: '100%',
        backgroundSize: { xs: 'cover', sm: 'cover', md: '100%', lg: '100%', xl: '100%' },
        backgroundRepeat: 'no-repeat',
        backgroundPosition: { xs: 'center -40px', sm: 'center', md: 'center', lg: 'center', xl: 'center' },
        backgroundImage: `url(${GenAImob})`,
        height: { xs: '100%', sm: '100%' },
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
    }

    const GenAITablet = {
        width: '100%',
        backgroundSize: { xs: 'cover', sm: 'cover', md: '100%', lg: '100%', xl: '100%' },
        backgroundRepeat: 'no-repeat',
        backgroundPosition: { xs: 'center -40px', sm: 'center', md: 'center', lg: 'center', xl: 'center' },
        backgroundImage: `url(${GenAIDesktop})`,
        height: { xs: '100%', sm: '100%' },
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: 'auto',
    }

    return (
        <>
            <Box sx={{
                position: "relative",
                width: "100%",
                height:{ xs: 'none', sm: 'none', md: '200px', lg: '200px', xl: '200px' , xxl:'270px'},
                justifyContent: "center",
                alignItems: "center",
                maxWidth: '100%',
                margin: 'auto',
                top: -'-3rem',
                display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' },
                zIndex: '1111'
            }}
            >
                <img src={GenAIDesktop} alt="" className={classes.image} />
            </Box>

            <Box sx={{
                position: "relative",
                width: "100%",
                height: "240px",
                justifyContent: "center",
                alignItems: "center",
                margin: 'auto',
                display: { xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none' },
                zIndex: { xs: '1', sm: '1', md: '1111', lg: '1111', xl: '1111' },
            }}>
                <Box component="image" sx={GenAImobImage}></Box>


            </Box>

            <Box sx={{
                position: "relative",
                width: "100%",
                height: "240px",
                justifyContent: "center",
                alignItems: "center",
                margin: 'auto',
                display: { xs: 'none', sm: 'block', md: 'none' },
                zIndex: { xs: '1', sm: '1', md: '1111', lg: '1111', xl: '1111' },
            }}>
                <Box component="image" sx={GenAITablet}></Box>
            </Box>
            <GenAICard />
        </>
    );
};

export default GenAI