import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Typography, Button, Box, useTheme } from '@mui/material';
import { useActiveLink } from '../../ActiveLinkContext';
import { makeStyles } from '@mui/styles';
import aiImage from '../../images/GenAI.svg';
import Vector from '../../images/Vectors.png';
import mobileVector from '../../images/mobAbs.svg';
import { globalColors } from '../../color';
import { proximaNovaRegularFont, proximaNovaLightFont, globalFontSize } from '../../fonts';

const useStyles = makeStyles({
    image: {
        maxWidth: "100%",
        height: "auto",
    },
    container: {
        margin: '0px 10rem',
        height: '500px',
    },
    muipadding: {
        paddingLeft: '0px',
        paddingRight: '0px',
    }
});

const Banner: React.FC = () => {
    const theme = useTheme();
    const customStyle = {
        paddingTop: '0px',
        paddingLeft: '0px',
    };
    const bannerTitle = {
        fontFamily: proximaNovaLightFont.fontFamily,
        marginBottom: '0.5rem',
        color: '#1F1C61',
    };
    const bannerSubHeading = {
        fontFamily: proximaNovaRegularFont.fontFamily,
        color: '#61607B',
        fontWeight: '300',
        lineHeight: '1.7rem',
    };
    const buttonText = {
        color: '#fff',
        backgroundColor: globalColors.color_2,
        fontFamily: proximaNovaRegularFont.fontFamily,
        fontWeight: 700,
        borderRadius: '12.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0.5rem 1.0rem',
        gap: '0.625rem',
        lineHeight: 'normal',
        border: 'none',

    };
    const absImage = {
        top: '0px',
        right: 0,
        width: '780px',
        height: '600px',
        zIndex: 1111,
        position: 'absolute',
        display: { xs: 'none', sm: 'none', md: 'initial', lg: 'initial', xl: 'initial' },
    };

    const banneroutestWrapper = {
        maxWidth: '1366px',
        width: { xs: 'auto', sm: 'auto', md: 'initial', lg: 'initial', xl: 'initial' },
        margin: { xs: '0rem 1.3rem 0rem 1.3rem', sm: '0rem 1.3rem 0rem 1.3rem', md: '0rem 3rem', lg: '0rem 10rem', xl: '0rem 10rem', xxl:'auto' },
        position: { xs: 'relative', sm: 'relative', md: 'relative', lg: 'relative', xl: 'relative' },
        paddingTop: { xs: '15px', sm: '15px', md: '0px', lg: '0px', xl: '0px' },
        zIndex: { xs: '1', sm: '1', md: theme.zIndex.drawer + 0, lg: theme.zIndex.drawer + 0, xl: theme.zIndex.drawer + 0 },
        height: { xs: 'fit-content', sm: 'fit-content', md: '500px', lg: '500px', xl: '500px' },
        top: { xs: 'unset', xm: 'unset', md: 'unset', lg: '-90px', xl: '-90px' },

    }

    const bannerRightImg = {
        backgroundSize: { md: 'cover', lg: 'cover', xl: 'contain' },
        backgroundRepeat: 'no-repeat',
        backgroundPosition: { md: 'center 0px', lg: 'center 0px', xl: 'center 0px' },
        backgroundImage: `url(${aiImage})`,
        width: '100%',
        height: { md: '500px', lg: '500px', xl: '680px' }
    }

    // code for onclick scrolling 
    const classes = useStyles();
    const { activeLink, setActiveLink } = useActiveLink();
    const location = useLocation();
    const navigate = useNavigate();


    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleSectionClick = (event: React.MouseEvent<HTMLAnchorElement>, section: string) => {
        event.preventDefault();
        setActiveLink(section);
        if (location.pathname !== "/") {
            navigate("/", { state: { scrollTo: section } })
        } else {
            scrollToSection(section);
        }
    };

    return (
        <Box className={classes.container} sx={banneroutestWrapper}>
            <Grid container sx={absImage}>
                <img src={Vector} alt="" className={classes.image} style={{height:'550px'}}/>
            </Grid>
            <Grid sx={{
                display: {
                    xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none',
                    position: 'absolute', width: '15rem', right: '-20px'
                },
                top: { xs: '-58px', sm: '-58px', md: '-7px', lg: '-7px', xl: '-7px' },
            }
            }>
                <img src={mobileVector} alt="" className={classes.image} width={'100%'} />
            </Grid>
            <Grid container spacing={3} alignItems="center" sx={{
                position: 'relative', zIndex: 11111, marginLeft: "0px",
                width: { xs: '100%', sm: '100%', md: 'initial', lg: 'initial', xl: 'initial' },
                marginTop: { xs: 'auto', sm: 'auto', md: 'initial', lg: 'initial', xl: 'initial' }
            }}>
                <Grid item xs={12} md={6} style={customStyle} sx={{ zIndex: 111111 }}>
                    <Typography variant='h2' style={bannerTitle}
                        sx={{ fontSize: { xs: globalFontSize.font_28, sm: globalFontSize.font_28, md: globalFontSize.font_48, lg: globalFontSize.font_48, xl: globalFontSize.font_48 } }
                        }>
                        Enterprise GenAI SaaS
                    </Typography>
                    <Typography variant='h6' color="textSecondary" style={bannerSubHeading}
                        sx={{
                            fontSize: {
                                xs: globalFontSize.font_15, sm: globalFontSize.font_15, md: globalFontSize.font_17, lg: globalFontSize.font_17, xl: globalFontSize.font_17,
                                marginBottom: { xs: '0rem', sm: '0rem', md: '0.5rem', lg: '0.5rem', xl: '0.5rem' }
                            }
                        }
                        }>
                        Unleash the power of Generative AI with Lab45 AI Platform! <br />The SaaS platform is designed to revolutionize productivity and efficiency for enterprises.
                    </Typography>
                    <Grid sx={{
                        display: 'flex', gap: '25px', marginTop: '25px', marginLeft: '0px',
                        justifyContent: { xs: 'center', sm: 'center', md: 'initial', lg: 'initial', xl: 'initial' }
                    }}>
                        <Button href='/#contact-us' onClick={(e) => handleSectionClick(e, "contact-us")} variant='outlined' color="primary" style={buttonText} sx={{
                            textTransform: 'capitalize',
                            fontSize: { xs: globalFontSize.font_18, sm: globalFontSize.font_18, md: globalFontSize.font_13, lg: globalFontSize.font_13, xl: globalFontSize.font_13 }
                        }}>
                            Contact Us
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} style={customStyle}
                    sx={{ display: { xs: 'none', sm: 'none', md: 'inherit', lg: 'inherit', xl: 'inherit' } }}>
                    <Box sx={bannerRightImg}></Box>
                </Grid>
            </Grid>
        </Box>
    )
}
export default Banner