import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { proximaNovaBoldFont } from '../../fonts';
import { globalColors } from '../../color';
import microsoftLogo from '../../images/Microsoft.svg';
import microsoftMobileLogo from '../../images/microsoftMobile.svg';
import googleLogo from '../../images/google.svg';
import googleLogoMobile from '../../images/googleMobile.svg';
import awsLogo from '../../images/aws.svg';
import awsLogoMobile from '../../images/awsMobile.svg';
import openaiLogo from '../../images/OpenAI.svg';
import openaiLogoMobile from '../../images/openaiMobile.svg';
import metaLogo from '../../images/Meta.svg';
import metaLogoMobile from '../../images/metaMobile.svg';
import topCoder from '../../images/image 20.svg';
import topCoderMobile from '../../images/topcoderMobile.svg';
import diceID from '../../images/image 21.svg';
import diceidMobile from '../../images/diceidMobile.svg';

const useStyles = makeStyles({
  contactWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    flexDirection: 'column',
  },
  sunHeading: {
    color: '#8A8A8A',
    textAlign: 'center',
    fontFamily: 'Proxima Nova Regular',
    fontSize: '1.3rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },

  partnersHeading:{
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logoWrapper:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '3rem',
  }

});

const Partners: React.FC = () => {
  const classes = useStyles();
  const title = {
    color: globalColors.color_4,
    fontFamily: proximaNovaBoldFont.fontFamily,
    fontSize: {xs:'1.8rem', sm:'1.8rem', md:'1.8rem', lg:'2.2rem', xl:'2.2rem'},
    paddingBottom: { xs: '0.5rem', sm: '0.5rem', md: 'unset', lg: 'unset', xl: 'unset' }, 
    fontWeight: 700,
    lineHeight: 'normal',
  };
  const partnerOutestContainer = {
    flexDirection: 'column', 
    marginTop:{xs:'auto', sm:'auto', md:'4.5rem', lg: '4.5rem', xl: 'auto'},
    margin:{xs:'4rem 0 0 0', sm:'4rem 0 0 0', md:'4rem 0 0 0', lg:'4.5rem 0 0 0', xl:'4.5rem auto'},
    maxWidth:{md: '1366px', lg: '1366px', xl: '1366px'},
    padding:{xs: '0rem 1.3rem', sm: '0rem 1.3rem', md: '0rem', lg: '0rem', xl:'0rem'},
  }

  return (
    <Grid container id="partners" sx={partnerOutestContainer}>
      <Grid item className={classes.partnersHeading} sx={{flexDirection: 'column', width:{xs: '100%', sm: '100%', md: 'auto', lg: 'auto'}, textAlign: {xs: 'center', sm: 'center', md: 'center', lg: 'center'}}}>
        <Typography sx={title}>
          <span>LLM Partners</span>
        </Typography>
        <Typography>
          <span className={classes.sunHeading}>Wipro Lab45 AI Platform is proudly partnered with the leaders in the industry</span>
        </Typography>
      </Grid>
      <Grid item className={classes.logoWrapper} sx={{paddingTop:{xs: '0rem', sm: '0rem', md: '3rem', lg: '3rem'},
       flexDirection:{xs: 'column', sm: 'column', md: 'row', lg: 'row'}, 
       gap:{xs: '2rem', sm: '2rem', md: '3rem', lg: '3rem'},
       display:{xs:'none', sm:'none', md:'flex', lg:'flex', xl:'flex'},
      width:{xs: '100%', sm: '100%'}}}>
        <img src={microsoftLogo} alt='Microsoft'/>
        <img src={googleLogo} alt='Google' />
        <img src={awsLogo} alt='AWS' />
        <img src={openaiLogo} alt='OpenAI' />
        <img src={metaLogo} alt='Meta' />
      </Grid>


      <Grid item className={classes.logoWrapper} sx={{paddingTop:{xs: '1.3rem ', sm: '1.3rem', md: '3rem', lg: '3rem',},
       flexDirection:{xs: 'row', sm: 'row', md: 'row', lg: 'row'}, 
       gap:{xs: '2rem', sm: '2rem', md: '3rem', lg: '3rem'},
       display:{xs:'flex', sm:'flex', md:'none', lg:'none', xl:'none'},
       flexWrap:{xs:'wrap', sm:'wrap'},
      width:{xs: '100%', sm: '100%'}}}>
        <img src={microsoftMobileLogo} alt='Microsoft'/>
        <img src={googleLogoMobile} alt='Google' />
        <img src={awsLogoMobile} alt='AWS' />
        <img src={openaiLogoMobile} alt='OpenAI' />
        <img src={metaLogoMobile} alt='Meta' />
      </Grid>

      <Grid item className={classes.partnersHeading} 
      sx={{flexDirection: 'column', 
      paddingTop: {xs: '2rem', sm: '2rem', md: '6rem', lg: '6rem'}, 
      textAlign:{xs: 'center', sm: 'center'}, paddingBottom: '1rem'}}>
        <Typography sx={title}>
          <span>Ecosystem Partner</span>
        </Typography>
      </Grid>
      <Grid item className={classes.logoWrapper} sx={{flexDirection:{xs: 'row', sm: 'row', md: 'row', lg: 'row', xl:'row'}, 
      gap:{xs: '2rem', sm: '2rem', md: '3rem', lg: '3rem'},
      display:{xs:'none', sm:'none', md:'flex', lg:'flex', xl:'flex'},
      }}>
        
        <img src={topCoder} alt='Top Coder' />
        <img src={diceID} alt='Dice ID' />
      </Grid>

      <Grid item className={classes.logoWrapper} sx={{flexDirection:{xs: 'row', sm: 'row', md: 'row', lg: 'row', xl:'row'}, 
      gap:{xs: '2rem', sm: '2rem', md: '3rem', lg: '3rem'},
      display:{xs:'flex', sm:'flex', md:'none', lg:'none', xl:'none'},
      }}>
        <img src={topCoderMobile} alt='Top Coder' />
        <img src={diceidMobile} alt='Dice ID' />
      </Grid>

    </Grid>
  )
}

export default Partners