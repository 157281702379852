import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button, ThemeProvider, Typography, createTheme } from '@mui/material';
import '../../src/slick-custom.css';
import { globalFontSize, globalFontWeight, proximaNovaBoldFont, proximaNovaRegularFont } from '../fonts';
import { makeStyles } from '@mui/styles';

const theme = createTheme();
const useStyles = makeStyles({
    root: {
        zIndex: `${theme.zIndex.drawer + 1} !important`,
        overflowY:'scroll',
    }
})

interface PrivacyPolicyPopUpProps {
    open: boolean;
    onClose: () => void;
}
const TermsCondiations: React.FC<PrivacyPolicyPopUpProps> = ({ open, onClose }) => {
    const classes = useStyles();
    const Dialogcontent = {
        fontSize: globalFontSize.font_15,
        fontFamily: proximaNovaRegularFont.fontFamily,
        width: '100%'
    };
  const liLeft = {
    fontSize: globalFontSize.font_15,
    fontFamily: proximaNovaRegularFont.fontFamily,
    width: '100%',
    marginLeft:'20px',
  };
  const liLeftBold = {
    fontSize: globalFontSize.font_15,
    fontFamily: proximaNovaBoldFont.fontFamily,
    fontWeight: globalFontWeight.font_700,
    width: '100%',
    marginLeft:'20px',
  }
    return (
        <ThemeProvider theme={theme}>
            <Dialog classes={{ root: classes.root }} open={open} aria-labelledby='privacy-policy-title' sx={{
                '& .MuiDialog-paper': {
                    maxWidth: '85%',
                },
            }}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        onClose();
                    }
                }}>
                <DialogTitle id="privacy-policy-title">Terms & Conditions</DialogTitle>
                <DialogContent >
                    <Typography paragraph style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>
                        YOU AGREED TO THIS DISCLAIMER UPON LOGGING INTO THE AI360 PLATFORM. SCROLL DOWN TO REVIEW THE AGREED TERMS OF USE.
                        <Typography paragraph style={Dialogcontent}>
                            These Terms of Use (“Terms”) apply to AI360 and are agreed to between Wipro Ltd. (“Wipro, “we,” “us,” or “our”) and you (“you” or “your”) and apply when you access or use AI360.
                        </Typography>
                        <Typography paragraph style={Dialogcontent}>
                            By accessing or using AI360, you acknowledge that you have read and accepted these Terms. We may update or replace these Terms from time-to-time by publishing a new version on AI360. By using AI360 after the “Last updated” date at the top of these Terms, you are deemed to have accepted the updated Terms. If you don’t agree to the updated Terms, you must stop accessing and using AI360.
                        </Typography>
                    </Typography>

                    <Typography paragraph style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>
                        Age Restriction
                    </Typography>

                    <Typography paragraph style={Dialogcontent}>
                        AI360 is intended for persons who are at least 18 or older and are of legal age to form a binding agreement. If you are under 18, or not of age to form a legally binding agreement, you may not access or use AI360.
                    </Typography>

                    <Typography paragraph style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>
                        Your Queries
                    </Typography>

                    <Typography paragraph style={Dialogcontent}>
                        AI360 is a core machine learning/artificial intelligence (“AI”) and generative AI platform. You may input content (“Queries”) into AI360 and receive responses (“Responses”) from the AI360 platform. You are solely responsible for your Queries, including that your Queries comply with all applicable laws and these Terms. You represent that you have the legal right and authority to access, use, input and disclose to AI360 all of the content in your Queries. You consent to the processing of your Queries by AI360. We reserve the right to reject your Queries or refuse to provide Responses if your Queries do not comply with applicable laws or these Terms.
                    </Typography>

                    <Typography paragraph style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>
                        Accuracy of AI360 and Restrictions
                    </Typography>

                    <Typography paragraph style={Dialogcontent}>
                        AI360 is powered by commercial large language models, including GPT 3.5/4 owned by OpenAI and PaLM2 owned by Google. Your use of AI360 must comply with the policies of OpenAI and Google, in addition to these Terms and applicable law.
                    </Typography>

                    <Typography paragraph style={Dialogcontent}>
                        AI systems are constantly evolving and rely on various sources of information, which may not always be accurate or current.We cannot and do not guarantee the accuracy, currency or completeness of the Responses.We accept no responsibility for the Responses at any time. You should use reasonable precautions in using AI360. You should not rely entirely on the responses without qualified human review. Without limiting the foregoing, you may not use AI360:
                    </Typography>

                    <Typography paragraph style={Dialogcontent}>
                        To engage in any activity that is fraudulent, false, or misleading. Do not attempt to upload, create or distribute content that could mislead or deceive others, including for example creation of disinformation, content enabling fraud, deceptive impersonation, or generate content for scams, phishing, or malware.
                    </Typography>

                    <Typography paragraph style={Dialogcontent}>
                        To infringe on the rights of others, including intellectual property rights.
                    </Typography>

                    <Typography paragraph style={Dialogcontent}>
                        For any purpose that could have legal or material harm to another person, such as automated-decision making based on a person’s profile or decisions related to a person’s ability to obtain housing, education, employment, and insurance or medical or legal decisions.
                    </Typography>
                    <Typography paragraph style={Dialogcontent}>
                        To create or distribute inappropriate content or material. You may not use AI360 to create or distribute spam, adult content, violence or gore, hateful content, terrorism and violent or extremist content, glorification of violence, child sexual exploitation or abuse material, or content that is otherwise disturbing or offensive.
                    </Typography>

                    <Typography paragraph style={Dialogcontent}>
                        To generate content or engage in any activity that has a risk of physical harm to yourself or others (such as weaponry, warfare or illegal drugs) or economic harm (such as gambling).
                    </Typography>
                    <Typography paragraph style={Dialogcontent}>
                        To generate content or engage in any activity for the purpose of tracking, monitoring, intimidating or harassing others.
                    </Typography>

                    <Typography paragraph style={Dialogcontent}>
                        To generate content that deceptively impersonates the work or style of another person;
                    </Typography>
                    <Typography paragraph style={Dialogcontent}>
                        To obtain or provide financial, legal, medical or other professional advice. Always consult a qualified professional for these services.
                    </Typography>

                    <Typography paragraph style={Dialogcontent}>
                        To do anything illegal on AI360. Your use of AI360 must comply with applicable laws at all times. AI laws are quickly developing. You are responsible for staying current and complying with laws applicable to your jurisdiction.
                    </Typography>
                    <Typography paragraph style={Dialogcontent}>
                        Responsible use of AI360 is required. You may not represent that Responses are human-generated. If you use all or part of a Response in any way, you must mark that the content was generated by AI.
                    </Typography>

                    <Typography paragraph style={Dialogcontent}>
                        Responses do not represent Wipro’s views and Wipro does not endorse the Responses or any references in Responses.
                    </Typography>

                    <Typography paragraph style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>
                        Privacy Policy
                    </Typography>

                    <Typography paragraph style={Dialogcontent}>
                        You may be required to provide us with information, for example, when you register for AI360 or contact us. This may involve providing us with your personal information. You agree that any such information provided is truthful and up-to-date. If we suspect that your information is inaccurate, duplicate or incomplete, we may suspend or terminate your access to AI360. Any personal information provided to us through your use of and/or in connection with AI360 will be collected and handled in accordance with our Privacy Policy.
                    </Typography>

                    <Typography paragraph style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>Passwords and Security</Typography>
                    <Typography paragraph style={Dialogcontent}>
                        You may be required to create an account to access AI360. Each person may have only one account. You may be asked to select a personal, non-transferable password when you create an account. If you submit a request for a lost or forgotten username or password, you may be asked to provide information to confirm your identity. You are solely responsible for all activities that occur under your password-protected account and for ensuring the protection of your account information. If you require a username or password reset, your information may be permanently deleted. You agree to notify us immediately of any unauthorized use of your password or account or any other breach of security that is known or suspected by you.
                    </Typography>
                    <Typography paragraph style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>Intellectual property of AI360</Typography>
                    <Typography paragraph style={Dialogcontent}>
                        We or our licensors (as applicable) own all copyright and all other intellectual property rights that may subsist in AI360, including without limitation content, trademarks, service marks, trade names, images, audio, text, software, designs and the “look and feel” of AI360. All rights not expressly granted to you in these Terms are reserved by us and/or our licensors.
                    </Typography>
                    <ul style={Dialogcontent}>
                        You must:
                        <li style={liLeft}>
                            Not remove any copyright, trademark and other proprietary notices contained in the content or otherwise on AI360.
                        </li>
                        <li style={liLeft}>
                            Not copy, reproduce, display, exploit, sell, modify, redistribute, transmit, publish, reverse engineer, decompile, disassemble, create derivative works, translate, sub-license or otherwise use for commercial purposes any portion of AI360 (including related software, source code, platforms, servers and interfaces, whether owned by us or third parties) without our prior written permission and if we provide such permission, include an attribution to Wipro Limited if you use any information contained on AI360.
                        </li>
                        <li style={liLeft}>
                            Not access or use AI360 in a manner prohibited by applicable laws or these Terms.
                        </li>
                    </ul>

                    <Typography paragraph style={Dialogcontent}>
                        The trademarks appearing on AI360 and our sites belong to us, our suppliers, or our licensors as applicable and are protected by law. You must not use or reproduce or allow anyone to use or reproduce those trademarks for any reason without prior written permission.
                    </Typography>

                    <Typography paragraph style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>
                        Ownership and Use of Content
                    </Typography>
                    <Typography paragraph style={Dialogcontent}>
                        As between you and Wipro, you retain all ownership rights to your Queries, to the extent permitted by applicable law. We and our licensors will not use your Queries to train AI360 or other AI services.<br/><br/>
                        We do not claim ownership of Responses. However, we do not represent that you or any other party will own all of the exclusive intellectual property rights in the Responses. Due to the nature of AI models, AI360 may generate Reponses with the same or similar content to multiple users. Likewise, there is no guarantee that the same or similar Queries will receive the same or similar Responses. You agree your use of Responses will comply at all times with all applicable laws and these Terms.
                    </Typography>
                    <Typography paragraph style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>
                        Prohibited Conduct
                    </Typography>
                    <ul>
                        Without limiting anything else herein, you agree to abide by all applicable laws and not to
                        <li style={liLeft}>(a) Upload, transmit, post, email, or otherwise make available to AI360 any material in any format that<br/>
                            (i) Is false, inaccurate, misleading, fraudulent, unlawful, harmful, threatening, tortious, defamatory, vulgar, invasive of another's privacy, or libelous.<br/><br/>
                            (ii) Infringes any third party's intellectual property or other proprietary right or rights of publicity or privacy; or<br/><br/>
                            (iii) Contains viruses, worms, Trojan horses, time bombs, corrupted files, or any other software or programs designed to interrupt, interfere, intercept, expropriate, destroy or limit the functionality of AI360 or any computer software or hardware or equipment associated with AI360 or access to AI360;<br/><br/>
                        </li>
                        <li style={liLeft}>(b) Impersonate any person or entity.</li><br/>
                        <li style={liLeft}>(c) Attempt, through any means, to gain unauthorized access to AI360 in a way not intended by us or for any unlawful purpose, or another person’s account or information on or through AI360.</li><br/>
                        <li style={liLeft}>(d) Use any robot, scraper, spider, or any other automatic device or manual process to monitor or copy AI360.</li><br/>
                        <li style={liLeft}>(e) Take any action that imposes an unreasonable or disproportionately large load on AI360.</li><br/>
                        <li style={liLeft}>(f) Take any action that creates liability for us or causes us to lose any of the services of our business partners, licensors, vendors or suppliers.</li><br/>
                        <li style={liLeft}>(g) Take any action that would cause us to violate any applicable law, statute, ordinance or regulation, or that violates these Terms.</li><br/>
                        <li style={liLeft}>(h) Attempt to tamper with, alter, disable, hinder, by-pass, override, or circumvent any security, reliability, integrity, restriction or requirement of AI360.</li><br/>
                        <li style={liLeft}>(i) Interfere with or disrupt the integrity or performance of AI360 or the data contained therein; or</li><br/>
                        <li style={liLeft}>(j) Access AI360 in order to build a competitive product or service, copy any features, functions or graphics of AI360 or monitor the availability and/or functionality of AI360 for any benchmarking or competitive purposes.</li><br/>
                    </ul>

                     <Typography paragraph style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>
                     Feedback and unsolicited submissions
                     </Typography>
                     <ul style={Dialogcontent}>
                     If you give us feedback or submit ideas about AI360 (“Ideas”), you:
                     <li style={liLeft} >Grant us the right to use, modify or develop those Ideas or variations on them to improve AI360 and our other products and services (and for any other purpose we deem necessary or desirable) without being obliged to seek permission or provide payment or any other compensation in respect of our use of those Ideas and without any other restriction.</li><br/>
                     <li style={liLeft}>Waive any moral rights in those Ideas and consent to us freely using those Ideas without further consultation, consent or attribution to you.</li><br/>
                     <li style={liLeft}>Irrevocably assign to us all right, title, and interest in and to those Ideas and agree to provide us any assistance we require to document, perfect, and maintain our rights in those Ideas. For any Ideas that cannot be legally assigned to us, you hereby grant us an exclusive, unrestricted, perpetual, royalty-free, irrevocable and worldwide license to disclose, reproduce, republish, modify, distribute, display, perform, transmit, sell, or otherwise use the Ideas for commercial or non-commercial purposes in any manner and medium with no compensation to you.</li><br/>
                     <li style={liLeft}>Warrant that you have all necessary rights and titles to grant us the right, title and interest described here.</li><br/>
                     </ul>
                     <Typography paragraph style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>Third party sites</Typography>
                     <Typography paragraph style={Dialogcontent}>
                     AI360 or Responses may include links to external sites. These sites are not under our control, and we are not responsible for them and we make no representations or warranties concerning the contents or security of any such external site. Such links are provided to you only as a convenience, and the inclusion of any link does not imply any recommendation, endorsement, verification, or certification by us of the linked site. Your use of linked sites is subject to the privacy policies and terms of use of those sites and you should read and understand them before using them. We expressly disclaim any responsibility for any harm resulting from your use or attempted use of linked sites.
                     </Typography>
                     <Typography paragraph style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>Disclaimer</Typography>
                     <Typography paragraph style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>
                     YOU AGREE THAT USE OF AI360 IS AT YOUR SOLE RISK. AI360 AND THE INFORMATION PRESENTED ON AI360, INCLUDING RESPONSES, IS PROVIDED ON AN “AS-IS” AND “AS-AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE EXPRESSLY DISCLAIM ANY WARRANTIES, REPRESENTATIONS, OR GUARANTEES OF ANY KIND (WHETHER, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE) INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF NON-INFRINGEMENT, MERCHANTABILITY, TITLE, QUIET ENJOYMENT, AND FITNESS FOR A PARTICULAR PURPOSE. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR AT OR THROUGH AI360 SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
                     </Typography>
                     <Typography paragraph style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>
                     WITHOUT LIMITING THE FOREGOING, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE MAKE NO WARRANTY THAT AI360, INCLUDING RESPONSES, WILL BE ACCURATE, COMPLETE, CURRENT OR TIMELY, UNINTERRUPTED, SECURE, OR ERROR FREE AND WE DO NOT WARRANT THAT AI360 IS FREE OF DEFECTS, VIRUSES, MALFUNCTIONS, OR HARMFUL COMPONENTS THAT COULD DAMAGE OR ALLOW UNAUTHORIZED ACCESS TO YOUR DATA.
                     </Typography>
                     <Typography paragraph style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>
                     Limitation of Liability
                     </Typography>
                     <Typography paragraph style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>
                     TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER WE NOR OUR SUPPLIERS, LICENSORS OR AFFILIATES (OR OUR RESPECTIVE OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, AGENTS, OR REPRESENTATIVES OR THEIR RESPECTIVE SUCCESSORS AND ASSIGNS) WILL BE LIABLE UNDER THE LAW OF TORT (INCLUDING NEGLIGENCE), CONTRACT OR OTHERWISE FOR ANY LOSS OF INCOME OR REVENUE, PROFITS, DATA, BUSINESS OPPORTUNITY, EMPLOYMENT OPPORTUNITY, OR SAVINGS; DAMAGED DEVICES OR OTHER INTANGIBLES; OR FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, PUNITIVE OR SPECIAL LOSS OR DAMAGE OF ANY PERSON, HOWEVER CAUSED, ARISING OUT OF OR IN CONNECTION WITH YOUR ACCESS TO OR USE OF AI360, EVEN IF WE OR YOU HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IF ANY PART OF THIS LIMITATION OF LIABILITY IS FOUND TO BE INVALID OR UNENFORCEABLE FOR ANY REASON OR IF WE ARE OTHERWISE FOUND TO BE LIABLE TO YOU IN ANY MANNER, THEN YOU AGREE THAT OUR TOTAL LIABILITY TO YOU FOR DAMAGES, REGARDLESS OF THE FORM OF ACTION, SHALL NOT EXCEED 6 MONTHS OF FEES PAID TO US FOR LICENSE OF AI 360, IN THE AGGREGATE FOR ALL CLAIMS. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. THEREFORE, THE EXCLUSIONS SET FORTH ABOVE MAY NOT APPLY TO YOU.
                     </Typography>
                     <Typography paragraph style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>
                     Indemnification
                     </Typography>
                     <ul  style={Dialogcontent}>
                     You (the indemnifying party) agree to indemnify, hold harmless, and release us and our suppliers, licensors and affiliates (and our respective officers, directors, shareholders, employees, agents, contractors, representatives (and their respective successors and assigns) (collectively, the indemnified party) from and against any and all claims, demands, liabilities, damages, costs and expenses, including, but not limited to, attorney's fees and costs, arising from or related to:
                     <li style={liLeftBold}>(i) Your access, use, attempted use, inability to use or misuse of AI360.</li><br/>
                     <li style={liLeftBold}>(ii) Your violation of any terms of these Terms or any applicable law.</li><br/>
                     <li style={liLeftBold}>(iii) Your violation of any third party right, including without limitation any copyright, property or privacy right.</li><br/>
                     <li style={liLeftBold}>(iv) Any claim that your use of AI360 caused damage to a third party. We may assert our indemnification rights against you at any time.</li><br/>
                     </ul>
                     <Typography style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>
                     Survival
                     </Typography>
                     <Typography style={Dialogcontent}>
                     The Disclaimer, Limitation of Liability, Indemnification and such other clauses that should survive to enforce these Terms shall survive the termination of these Terms for any reason.
                     </Typography>
                     <br/>
                     <Typography style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>
                     Jurisdiction
                     </Typography>
                     <Typography style={Dialogcontent}>
                     AI360 and these Terms are governed by the laws of India and the courts of India will have non-exclusive jurisdiction to hear and determine any dispute arising in relation to them. You must not object to the transfer of any proceedings to Indian courts on any basis, including inconvenience.
                     </Typography>
                     <br/>
                     <Typography style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>
                     Copyright Infringement
                     </Typography>
                     <Typography style={Dialogcontent}>
                     If you believe your copyright rights have been infringed, please send written notice to:<a href="mailto:support.ai360@wipro.com">support.ai360@wipro.com</a>
                     </Typography>

                     <ul  style={Dialogcontent}>
                     The notice must contain:
                     <li style={liLeft}>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest.</li><br/>
                     <li style={liLeft}>A description of the location of where the alleged infringing content appears.</li><br/>
                     <li style={liLeft}>A description of the copyrighted work that you claim has been infringed, including any registration information.</li><br/>
                     <li style={liLeft}>Your address, telephone number, and email address.</li><br/>
                     <li style={liLeft}>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.</li><br/>
                     <li style={liLeft}>A statement by you that the information in your notice is accurate and, under penalty of perjury, that you are the copyright owner or authorized to act on the copyright owner's behalf.</li><br/>
                     </ul>
                    
                     <Typography style={Dialogcontent} sx={{ fontWeight: globalFontWeight.font_700 }}>
                     Miscellaneous
                     </Typography>
                     <Typography style={Dialogcontent}>
                     You agree that we may send to you in electronic form any notices or other communications regarding AI360 and such electronic form will satisfy any legal requirements with respect to communications or notice.
                     </Typography><br/>

                     <Typography style={Dialogcontent}>
                     If any part of these Terms shall be held or declared to be invalid or unenforceable for any reason by any court of competent jurisdiction, such provision shall be ineffective but shall not affect any other part of these Terms, and in such event, such provision shall be changed and interpreted so as to best accomplish the objectives of such unenforceable or invalid provision within the limits of applicable law or applicable court decisions.
                     </Typography><br/>

                     <Typography style={Dialogcontent}>
                     Our failure to partially or fully exercise any rights or our waiver of any breach of these Terms shall not prevent our subsequent exercise of such right or be deemed a waiver of any subsequent breach of the same or any other term of these Terms. Our rights and remedies under these Terms and any other applicable agreement between us and you shall be cumulative, and the exercise of any such right or remedy shall not limit our right to exercise any other right or remedy.
                     </Typography><br/>

                     <Typography style={Dialogcontent}>
                     You may not assign these Terms or any of your rights or obligations thereunder. We may assign these Terms and any of our rights and obligations hereunder to an affiliate, subsidiary, or successor-in-interest.
                     </Typography><br/>

                     <Typography style={Dialogcontent}>
                     We may at any time, without notice and for any reason in our sole discretion, modify or discontinue AI360, delete content, or terminate or restrict your access to AI360.
                     </Typography><br/>

                     <Typography style={Dialogcontent}>
                     Questions regarding these Terms should be directed to:support.<a href="mailto:ai360@wipro.com">ai360@wipro.com</a>
                     </Typography>

                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}
export default TermsCondiations