import React from 'react';
import { Grid, Typography, Box, Card, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { proximaNovaRegularFont, proximaNovaAltLightFont, proximaNovaAltBoldFont, globalFontWeight, globalFontSize, proximaNovaBoldFont } from '../../../fonts';
import { globalColors } from '../../../color';
import Efficiencyicon from '../../../images/Efficiency.svg';
import Responseicon from '../../../images/Response.svg';
import Scalibilityicon from '../../../images/Scalibility.svg';
import Costsicon from '../../../images/Costs.svg';
import Bgusecases from '../../../images/banner.svg';

const useStyles = makeStyles({
    image: {
        width: "100%",
    },
    partnersHeading: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
    },
});

interface Service {
    id: number,
    icon: string,
    title: string,
}

const serviceTask: Service[] = [
    {
        id: 1,
        icon: Efficiencyicon,
        title: "Efficiency",
    },

    {
        id: 2,
        icon: Responseicon,
        title: "Response Times",
    },

    {
        id: 3,
        icon: Scalibilityicon,
        title: "Scalability",
    },

    {
        id: 4,
        icon: Costsicon,
        title: "Ultimately save costs",
    },
];


const Support: React.FC = () => {
    const classes = useStyles();
    const heading = {
        color: globalColors.color_2,
        fontFamily: proximaNovaAltLightFont.fontFamily,
        fontWeight: globalFontWeight.font_300,
    };

    const CardInnerWrap: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        margin: 'auto',
        justifyContent: 'center',
        gap: '0.95rem',
        padding: '1.3rem 0rem',
        borderRadius: '1.3rem',
        border: '1px solid #F4EBFF',
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(180deg, #FAFAFF 0%, #FAF6FF 100%)',
    };

    const WrapInline: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0rem',
    };
    const TitleStyle: React.CSSProperties = {
        color: globalColors.color_16,
        fontFamily: proximaNovaBoldFont.fontFamily,
        fontSize: globalFontSize.font_15v2,
        fontWeight: globalFontWeight.font_700,
    }
    const HeadingStyle: React.CSSProperties = {
        color: globalColors.color_3,
        fontWeight: globalFontWeight.font_700,
        fontFamily: proximaNovaAltBoldFont.fontFamily,
        textAlign: 'center',
    }
    const title = {
        color: globalColors.color_4,
        fontFamily: proximaNovaAltLightFont.fontFamily,
        fontWeight: globalFontWeight.font_300,
    };
    const subHeading = {
        color: globalColors.color_2,
        fontFamily: proximaNovaRegularFont.fontFamily,
        fontWeight: globalFontWeight.font_400,
        letterSpacing: 'unset',
        lineHeight: '1.71rem',
    };

    const absImage = {
        width: '100%',
        backgroundSize: { xs: 'cover', sm: 'cover', md: 'cover', lg: 'cover', xl: 'cover' },
        backgroundRepeat: 'no-repeat',
        backgroundPosition: { xs: 'center', sm: 'center', md: 'center', lg: 'center', xl: 'center' },
        backgroundImage: `url(${Bgusecases})`,
        height: { xs: '470px', sm: '470px', md: '100vh', lg: '100vh', xl: '100vh' }
    }
    return (
        <Grid container sx={{
            margin: { xs: '0rem 0rem 4rem 0rem', sm: '0rem 0rem 4rem 0rem', md: 'auto', lg: 'auto', xl: 'auto' },
            width: { xs: 'auto', sm: 'auto', md: '100%', lg: '100%', xl: '100%' },
            position: { xs: 'relative', sm: 'relative', md: 'inherit', lg: 'inherit', xl: 'inherit' },
            height: { xs: 'initial', sm: 'initial', md: 'initial', lg: 'initial', xl: 'initial' },
            paddingTop: { xs: 'initial', sm: 'initial', md: 'initial', lg: 'initial', xl: 'initial' },
        }}>
            <Box sx={absImage}></Box>
            <Grid sx={{
                margin: { xs: '0rem 1.3rem', sm: '0rem 1.3rem', md: 'auto', lg: 'auto' },
                paddingBottom: { xs: '0', sm: '0', md: '0', lg: '0', xl: '0' },
                position: { xs: 'absolute', sm: 'absolute', md: 'absolute', lg: 'absolute', xl: 'absolute' },
                top: { xs: '60px', sm: '100px', md: '4rem', lg: '8rem', xl: '8rem' },
                bottom: { xs: 'initial', sm: 'initial', md: 'initial', lg: 'initial', xl: 'initial' },
                height: { xs: 'initial', sm: 'initial', md: 'fit-content', lg: 'fit-content', xl: 'fit-content' },
                left:'0px',
                right:'0px'

            }}

                item className={classes.partnersHeading} flexDirection={'column'}>
                <Typography style={title}
                    sx={{
                        fontSize: { xs: globalFontSize.font_32, sm: globalFontSize.font_32, md: globalFontSize.font_48, lg: globalFontSize.font_48, xl: globalFontSize.font_48 },
                    }} >
                    <span>Finance</span>
                </Typography>
                <Typography style={subHeading} textAlign={'center'}
                    sx={{ fontSize: { xs: globalFontSize.font_15, sm: globalFontSize.font_15, md: globalFontSize.font_17, lg: globalFontSize.font_17, xl: globalFontSize.font_17 } }}>
                    <span>GenAI revolutionizes finance by optimizing processes, providing insights,<br/>and enhancing decision-making for better outcomes.</span>
                </Typography>
                <Typography variant='h2' style={heading}
                    sx={{
                        textAlign: 'center', letterSpacing: 'normal',
                        fontSize: { xs: globalFontSize.font_19, sm: globalFontSize.font_19, md: globalFontSize.font_32, lg: globalFontSize.font_32, xl: globalFontSize.font_32 },
                        margin: { xs: '0rem', sm: '0rem', md: '0rem 10rem', lg: '0rem 10rem', xl: '0rem 10.5rem' },
                        paddingTop: { xs: '2rem', sm: '2rem', md: '3.8rem', lg: '3.8rem', xl: '3.8rem' },
                        lineHeight: { xs: '1.7rem', sm: '1.7rem', md: '3rem', lg: '3rem', xl: '3rem' }
                    }}>
                    By implementing generative AI in customer support, businesses can automate routine inquiries, allowing human agents to focus on complex issues.
                </Typography>
            </Grid>
            {/*image card */}
            <Grid sx={{
                flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' },
                margin: { xs: '0rem 1.3rem', sm: '0rem 1.3rem', md: 'auto', lg: '0rem 10rem', xl: '0rem 10rem', xxl:'auto' },
                width: { xs: '100%', sm: '100%', md: 'auto', lg: '100%', xl: '100%' },
                paddingTop: { xs: '0rem', sm: '0rem', md: 'inherit', lg: 'inherit', xl: 'inherit' },
                maxWidth:{ xxl:'1366px' }
            }}>
                <Box>
                    <Typography style={HeadingStyle} sx={{
                        lineHeight: '3rem', paddingBottom: '0.95rem',
                        fontSize: { xs: '1.8rem', sm: '1.8rem', md: '2rem', lg: '2rem', xl: '2rem' },
                    }}>This will improve:</Typography>
                </Box>
                <Box sx={{
                    display: 'flex', flexDirection: 'row',
                    gap: { xs: '1rem', sm: '1rem', md: '1.5rem', lg: '1.5rem', xl: '1.5rem' },
                    flexWrap: { xs: 'wrap', sm: 'wrap', md: 'nowrap', lg: 'nowrap', xl: 'nowrap' }
                }}>

                    {serviceTask.map((service, id) => (
                        <Card style={CardInnerWrap} key={service.id} sx={{
                            width: '13.8rem', minHeight: '9.4rem', margin: 'auto', boxShadow: '0px 0px 34px 0px #FAEDFF',
                            flexBasis: { xs: '46%', sm: '46%', md: 'unset', lg: 'unset', xl: 'unset' }
                        }} >
                            <CardContent style={WrapInline}>
                                <Box sx={{ width: '3.8rem' }}>
                                    <img src={service.icon} alt="" className={classes.image} />
                                </Box>
                                <Box>
                                    <Typography style={TitleStyle} sx={{ lineHeight: '1.42rem' }}>{service.title}</Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    ))}

                </Box>
            </Grid>


        </Grid>
    )
}
export default Support