import React, { useEffect } from 'react';
import { makeStyles, styled } from '@mui/styles';
import { Grid, Typography, Box } from '@mui/material';
import { proximaNovaBoldFont, proximaNovaRegularFont, globalFontSize, globalFontWeight } from '../../fonts';
import { globalColors } from '../../color';
import {blogsListing, ContentItem} from '../blogs/BlogsContent';
import { useLocation } from 'react-router-dom';
import { useActiveLink } from '../../ActiveLinkContext';
import Header from '../home/Header';
import Footer from '../Footer';

const useStyles = makeStyles({
    image: {
        width: '100%'
    },
});

const Blogs:React.FC = ()=>{
    const classes = useStyles();
    const location  = useLocation();
    const {setActiveLink} = useActiveLink();

    useEffect(()=>{
        if (location.pathname === "/blogs") {
            window.scroll({behavior:'smooth'})
            window.scrollTo(0, 0);
            setActiveLink("blogs")
        }else{
            setActiveLink("blogs")
        }
    }, [location.pathname, setActiveLink])



    const CustomerTitle = {
    color: globalColors.color_4,
    fontFamily: proximaNovaBoldFont.fontFamily,
    lineHeight: 'normal',
    fontWeight: globalFontWeight.font_700, 
    };

    const Description = {
        color: globalColors.color_7,
        fontFamily: proximaNovaRegularFont.fontFamily,
    };
      
    return (
        <>
         <Header/>
        <Grid container sx={{maxWidth:{xl: '1366px'}, 
        margin:{xs:'4rem 1.3rem', sm:'4rem 1.3rem', md:'auto', lg:'auto', xl:'auto'},
        marginBottom:{xs:'0rem', sm:'0rem', md:'unset', lg:'unset', xl:'unset'},
        width:{xs:'auto', sm:'auto', md:'100%', lg:'100%', xl:'100%'},
        }} id="blogs">
           {blogsListing.map((item:ContentItem, index)=>(
               <Box position={'relative'} key={item.id} sx={{
               margin:{xs:'unset', sm:'unset', md:'4rem 0rem', lg:'4rem 0rem', xl:'4rem 0rem'},
               }}>
                  <Grid item xs={12} md={9} 
                  sx={{display: 'flex', alignItems: 'flex-start', margin: 'auto', flexDirection:'column', 
                  justifyContent:{xs:'left', sm:'left', md:'left', lg:'left', xl:'left'},
                  gap:'1.5rem',

                  }}>
                     <Box>
                        <Typography variant='body1' component="p"
                         style={CustomerTitle} sx={{fontSize:{xs:globalFontSize.font_15, sm:globalFontSize.font_15, md:globalFontSize.font_15, lg:globalFontSize.font_15, xl:globalFontSize.font_15}}} letterSpacing={'unset'}>{item.title_date}</Typography>
                     </Box>
                     <Box>
                        <Typography variant='h2'
                         style={CustomerTitle} sx={{fontSize:{xs:globalFontSize.font_32, sm:globalFontSize.font_32, md:globalFontSize.font_32, lg:globalFontSize.font_35, xl:globalFontSize.font_35}}} letterSpacing={'unset'}>{item.heading}</Typography>
                     </Box>
                     <Box sx={{display:'flex', flexDirection:'column', gap:'0.4rem'}}>
                     <Box>
                        <Typography variant='body1' component="p"
                         style={CustomerTitle} sx={{fontSize:{xs:globalFontSize.font_20, sm:globalFontSize.font_20, md:globalFontSize.font_20, lg:globalFontSize.font_20, xl:globalFontSize.font_20}}} letterSpacing={'unset'}>{item.author_title}</Typography>
                     </Box>
                     <Box>
                        <Typography variant='body1' component="p"
                         sx={CustomerTitle} letterSpacing={'unset'} >{item.author_designation}</Typography>
                     </Box>
                     </Box>
                  </Grid>
                  <Grid item container xs={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', 
                  margin:{xs:'0rem', sm:'0rem', md:'1rem 0rem 0rem 0rem', lg:'1rem 0rem 0rem 0rem', xl:'1rem 0rem 0rem 0rem'},
                  paddingBottom:{xs:'4rem', sm:'4rem', md:'unset', lg:'unset', xl:'unset'}
                  }}>
                  <Grid item xs={12} md={9} sx={{display: 'flex', flexDirection:'column'}}>
                  <Box>
                        <Typography variant='h2'
                         style={CustomerTitle} letterSpacing={'unset'} sx={{
                            fontSize:{xs:globalFontSize.font_20, sm:globalFontSize.font_20, md:globalFontSize.font_20, lg:globalFontSize.font_20, xl:globalFontSize.font_20},
                            padding:'0.4rem 0',
                         }}>
                            {item.blog_title}
                        </Typography>
                  </Box> 
                  <Box>
                        <Typography variant='h2' dangerouslySetInnerHTML={{__html:item.description}}
                        sx={{fontWeight: globalFontWeight.font_400, letterSpacing: 'unset', 
                        fontSize:{xs:globalFontSize.font_19, sm:globalFontSize.font_19, md:globalFontSize.font_15, lg:globalFontSize.font_15, xl:globalFontSize.font_15},
                        lineHeight:{xs:'1.8rem', sm:'1.8rem', md:'2rem', lg:'2rem', xl:'2rem'},
                        textAlign:'justify',
                        }} style={Description}></Typography>
                     </Box>
                  </Grid>
                  </Grid> 
               </Box>
            ))
           }
        </Grid>
        <Footer/>
        </>
    )
}
export default Blogs