export const globalColors = {
    color_0: "linear-gradient(180deg, #1C6DFF 0%, #C025FF 100%)",
    color_1: "linear-gradient(180deg, #1C6DFF 0%, #C025FF 100%)",
    color_2: "#351a55",
    color_3: '#9E3377',
    color_4: '#1F1C61',
    color_5: '#099487',
    color_6: '#793196',
    color_7: '#4E4F4F',
    color_8: '#1A0063',
    color_9: '#2c347b',
    color_10: '#8A8A8A',
    color_11: '#4282FE',
    color_12: '#BE266A',
    color_13: '#019975',
    color_14: '#E58029',
    color_15: '#666666',
    color_16: '#6C7197',
    color_17: '#1C6DFF',
    color_18: '#FFF',
}

export const globalBgColor = {
    background_0: '#f9f9f9',
}