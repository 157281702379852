import Trendicon from '../../images/image 22.svg';
export interface ContentItem {
    id: number,
    title: string,
    description: string,
    imageUrl: string
}
export const contentItems1: ContentItem[] = [
    {
        id:1,
        title: "Customer Trend Analysis",
        description: "Analyze customer data to identify trends, preferences, and behaviors, enabling personalized marketing, improved products, and proactive adaptation to future trends, empowering businesses to better serve customers.",
        imageUrl: Trendicon,
    },
    {
        id:2,
        title: "Agent Assist",
        description: "Resolves customer queries instantly with personalized responses, improving satisfaction, reducing response times, and allowing agents to focus on complex issues, enhancing customer experience and operational efficiency.",
        imageUrl: Trendicon,
    },
    {
        id:3,
        title: "Tailored Experiences",
        description: "By analyzing vast customer data, GenAI enables businesses to deliver highly personalized experiences, leading to increased satisfaction, loyalty, and improved business outcomes",
        imageUrl: Trendicon,
    },
    {
        id:4,
        title: "Synchronized Experience",
        description: "Generative AI synchronizes customer experiences across channels, ensuring consistency and personalization. AI-powered analytics and centralized customer data enable unified and seamless interactions, enhancing the overall customer experience",
        imageUrl: Trendicon,
    },
]

export const contentItems2: ContentItem[] = [
    {
        id:1,
        title: "Impactful Reporting",
        description: "Generative AI can assist in automating and enhancing ESG reporting processes, generating accurate and comprehensive reports on environmental, social, and governance performance, enabling businesses to transparently communicate their sustainability efforts.",
        imageUrl: Trendicon,
    },
    {
        id:2,
        title: "Driving ESG Excellence",
        description: "Generative AI enhances ESG performance by analyzing data to identify trends and risks, fostering sustainable innovation, and automating transparent reporting. This enables informed decisions, eco-friendly development, and effective communication with stakeholders.",
        imageUrl: Trendicon,
    },
    {
        id:3,
        title: "ESG Investment Analysis",
        description: "Generative AI can aid in ESG investment analysis by analysing diverse data sources, providing deeper insights into a company’s sustainability practices. This helps investors make informed decisions aligned with their ethical and financial objectives, ultimately driving sustainable and responsible investment practices.",
        imageUrl: Trendicon,
    },
    {
        id:4,
        title: "ESG Compliance",
        description: "Enable reporting managers to proactively identify and address data gaps, ensuring compliance with voluntary reporting standards and swiftly evolving regulations in order to maintain transparency and regulatory adherence.",
        imageUrl: Trendicon,
    }
]

export const contentItems3: ContentItem[] = [
    {
        id:1,
        title: "Improve the Customer Experience",
        description: "Generative AI enhances customer interactions by analyzing data, generating personalized content, and automating tasks. This includes personalized financial advice, 24/7 support, automated processes, sentiment analysis, and personalized customer journeys, driving satisfaction and business growth.",
        imageUrl: Trendicon,
    },
    {
        id:2,
        title: "Recommend personalized financial products",
        description: "Empower financial institutions to provide personalized products and services tailored to each customer's unique needs, enhancing the customer experience, increasing satisfaction, and fostering stronger relationships.",
        imageUrl: Trendicon,
    },
    {
        id:3,
        title: "Provide customized financial advice",
        description: "Transform financial advice by analyzing customer's financial situation and generating personalized recommendations on budgeting, debt management, and retirement planning. This tailored approach empowers customers to make informed financial decisions and achieve their long-term goals.",
        imageUrl: Trendicon,
    }
]
export const contentItems4: ContentItem[] = [
    
    {
        id:1,
        title: "Recruitment and Talent Acquisition",
        description: "Utilize Generative AI to create detailed, tailored job descriptions and ads, saving time and attracting a wider pool of candidates. AI revolutionizes candidate sourcing and screening, analyzing data and conducting initial interviews through chatbots.",
        imageUrl: Trendicon,
    },
    {
        id:2,
        title: "Employee Onboarding and Training",
        description: "Generative AI creates personalized onboarding experiences for new hires, generating customized plans based on role, interests, and learning pace. It also analyzes performance data to generate personalized training programs, enhancing skill development and career progression.",
        imageUrl: Trendicon,
    },
    {
        id:3,
        title: "Employee Engagement and Retention",
        description: "Generative AI can be used to predict employee turnover by analyzing patterns in employee behavior, engagement levels, and other HR data. By generating predictive insights, HR can proactively address issues that may lead to employee dissatisfaction and turnover, such as workload imbalances, lack of recognition",
        imageUrl: Trendicon,
    },
]

export const contentItems5: ContentItem[] = [
    {
        id:1,
        title: "Sales Content Generation",
        description: "Generative AI drives sales productivity through personalized marketing, utilizing customer data to generate tailored content and recommendations. By analysing customer behaviour and preferences, AI can create targeted advertisements and product recommendations, enhancing customer engagement and increasing the likelihood of conversion, ultimately driving sales and revenue.",
        imageUrl: Trendicon,
    },
    {
        id:2,
        title: "Leveraging Sales & Training Collateral",
        description: "Maximizing sales effectiveness. Analyse repositories to swiftly generate personalized content, aiding quick deal closures and proactive pitching, streamlining the sales process",
        imageUrl: Trendicon,
    },
    {
        id:3,
        title: "Sentiment Analysis",
        description: "Generative AI can help sales teams with sentiment analysis by analysing customer feedback, social media interactions, and other data sources to gauge customer sentiment. This can provide valuable insights into customer preferences and concerns, enabling sales teams to tailor their approach and messaging, ultimately enhancing productivity and customer satisfaction.",
        imageUrl: Trendicon,
    },
]
export const contentItem6: ContentItem[] = [
    {
        id:1,
        title: "Risk Management",
        description: "GenAI can empower supply chain planners to proactively manage risks by analyzing historical data, market conditions, and other factors. Instead of relying on pre-set dashboards, planners can use AI to generate customized risk assessments, scenario simulations, and mitigation strategies on demand. This enables them to identify and address potential disruptions more effectively, ensuring supply chain resilience.",
        imageUrl: Trendicon,
    },
    {
        id:2,
        title: "Contract Management",
        description: "Revolutionizes contract analysis by automating the extraction of crucial information. This enables the generation of concise summaries and valuable insights. By comparing contract terms, AI pinpoints potential risks and ensures compliance. Data-driven recommendations empower negotiators and facilitate efficient contract renewals. AI streamlines the entire contract lifecycle, enhancing accuracy, efficiency, and decision-making.",
        imageUrl: Trendicon,
    },
    {
        id:3,
        title: "Supplier Management",
        description: "Leverage natural language processing (NLP) to extract insights from supplier communications and data, efficiently supporting, overseeing, and evaluating interactions. NLP identifies supply chain challenges and enhances supplier relationships, fostering stronger partnerships and a resilient supply chain.",
        imageUrl: Trendicon,
    },
]