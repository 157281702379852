import React, { useEffect } from 'react';
import Header from '../../home/Header';
import Support from './Support';
import SupportList from './SupportList';
import Footer from '../../Footer';
import { useLocation } from 'react-router-dom';
import { useActiveLink } from '../../../ActiveLinkContext';


const CustomerExperience: React.FC = ()=>{
    const location  = useLocation();
    const {setActiveLink} = useActiveLink();
    

    useEffect(()=>{
        if (location.pathname === "/customer-experience") {
            window.scroll({behavior:'smooth'})
            window.scrollTo(0, 0);
            setActiveLink("use-cases")            
        }else{
            setActiveLink("customer-experience")
        }
    }, [location.pathname, setActiveLink])
    return (
       <>
        <Header/>
       <Support/>
       <SupportList/>
       <Footer/>
       </>
    )
}

export default CustomerExperience



