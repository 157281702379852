import React, { useEffect } from 'react';
import Header from './Header';
import Banner from './Banner';
import GenAI from './GenAI';
import Benefits from './Benefits';
import MaskLogo from './Mask';
import Partners from './Partners';
import MarketPlace from './MarketPlace';
import UseCases from './UseCases';
import Faq from './Faq';
import ContactUs from './ContactUs';
import { useLocation } from 'react-router-dom';
import Footer from '../Footer';
import { useActiveLink } from '../../ActiveLinkContext';
import Blogs from '../blogs/Blogs';
import Blog from './Blog';
const Home: React.FC = () => {
    const location = useLocation();
    const {setActiveLink} = useActiveLink();

    useEffect(()=>{
        if (location.state?.scrollTo) {
            const sectionId = location.state.scrollTo;
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({behavior: "smooth"});
                setTimeout(() => setActiveLink(sectionId), 500);
                
            }
        }
    }, [location.state, setActiveLink])
    return (
        <>
            <Header />
            <Banner />
            <GenAI />
            <Benefits/>
            <MaskLogo />
            <Partners/>
            <MarketPlace />
            <UseCases/>
            <Blog/> 
            <Faq/>
            <ContactUs/>
            <Footer/>
        </>
    )
}
export default Home

