import { createTheme, ThemeProvider } from "@mui/material/styles";


declare module '@mui/material/styles'{
    interface BreakpointOverrides{
            xs:true;
            sm:true;
            md: true;
            lg:true;
            xl:true;
            xxl:true;
    }
}
const customTheme = createTheme({
    breakpoints:{
        values:{
            xs:0,
            sm:600,
            md: 980,
            lg:1300,
            xl:1400,
            xxl:1850
        }
    }
});

export default customTheme