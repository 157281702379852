import React, { useState } from 'react';
import {Box, Container, Typography, Link} from "@mui/material"
import { globalColors } from '../color';
import {proximaNovaRegularFont, globalFontSize, globalFontWeight } from '../fonts';
import { makeStyles } from '@mui/styles';
import PrivacyPolicyPopUp from './PrivacyPolicy';
import TermsCondiations from './TermsCondiations';

const useStyles = makeStyles({
    image:{
        width: '100%'
    },
});
const Footer:React.FC = ()=>{
    const classes = useStyles();
    const [openPopup, setOpen] = useState(null);
    const handleOpen = (popupType:any)=> (event:any)=>{
        event.preventDefault();
        setOpen(popupType)
    }
    const handleClose = ()=>{
        setOpen(null)
    }

    const footerOuterWrapper = {
        backgroundColor: '#fff', 
        height: {xs: '100%', sm: '100%', md: '4.4rem', lg: '4.4rem', xl: '4.4rem'}, 
        borderTop:'1px solid #E8E8E8',
        display: 'flex', margin: {xs: '3rem 0 0 0', sm: '3rem 0 0 0', md: '0rem', lg: '0rem', xl: '0rem'},
        padding:{xs:'1.25rem 0', sm:'1.25rem 0', md:'initial', lg:'initial', xl:'initial'},
    };

    const footerContainer = {
        display: 'flex', alignItems: 'center', flexDirection:{xs:'column', sm: 'column', md:'row', lg: 'row', xl:'row'}, 
        justifyContent:{xs:'center', sm:'center', md:'center', lg:'center', xl:'center'},
        padding:{xs:'0px', sm:'0px'},
    };
    const buttonLink = {
        color: globalColors.color_17, fontWeight: globalFontWeight.font_400, 
        lineHeight: 'normal', 
        fontFamily: proximaNovaRegularFont.fontFamily, 
        textDecoration: 'none', 
        fontSize: {xs:globalFontSize.font_15, sm: globalFontSize.font_15, md:globalFontSize.font_12, xl:globalFontSize.font_12, lg:globalFontSize.font_12},
    };
    return(
        <>
        <Box component="footer" sx={footerOuterWrapper}>
           <Container sx={footerContainer}>
            <Box sx={{display: 'flex', gap: 1, flexDirection:{xs: 'column', sm: 'column', md: 'row', lg:'row', xl: 'row'}, 
            alignItems:{xs:'center', sm:'center'}}}>
                <Typography 
                sx={{fontSize: {xs:globalFontSize.font_15, sm: globalFontSize.font_15, md:globalFontSize.font_12, xl:globalFontSize.font_12, lg:globalFontSize.font_12}, fontFamily: proximaNovaRegularFont.fontFamily, fontWeight: globalFontWeight.font_400, color: globalColors.color_15}}>© 2024 Wipro Lab45 AI Platform</Typography>

                <Link component="button" onClick={handleOpen('terms')} variant='body2' color="textSecondary" sx={buttonLink}>
                    Terms and Conditions
                </Link>

                <Link href="/privacy-policy" onClick={handleOpen('privacy')} variant='body2' color="textSecondary" sx={buttonLink}>
                    Privacy Policy
                </Link>
            </Box>
           </Container>
           <PrivacyPolicyPopUp open={openPopup === 'privacy'} onClose={handleClose}/>
           <TermsCondiations open={openPopup === 'terms'} onClose={handleClose}/>
        </Box>
       
        </>
    )
}
export default Footer