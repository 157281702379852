import React from 'react';
import { Card, CardContent, Box, Typography, Grid, Button, useTheme} from '@mui/material';
import { makeStyles } from '@mui/styles';
import azureLogo from '../../images/image 16.svg';
import { globalColors } from '../../color';
import { proximaNovaBoldFont, globalFontSize, globalFontWeight } from '../../fonts';

const useStyles = makeStyles({
    marketplaceWrapper: {
        margin: 'auto',
        textAlign: 'center',
        display: 'flex', 
        justifyContent: 'center', 
         alignItems: 'center', 
      },

})
const MarketPlace: React.FC = () => {
    const theme = useTheme();
   const  heading = {
        fontFamily: proximaNovaBoldFont.fontFamily,
        fontWeight: globalFontWeight.font_700,
        lineHeight: 'normal',
    };
   const button={
        padding: '1rem 2rem',
        gap: '0.375rem',
        marginTop: '1rem',
        fontSize: globalFontSize.font_12,
        fontWeight: globalFontWeight.font_700,
        borderRadius: '4.5rem',
        background: '#3D216F',
        color: '#fff',
        lineHeight: '1rem',
        textTransform: 'capitalize', 
        '&:hover':{
            background: '#3D216F',
         }

    };
    const classes = useStyles();
    return (
        <Box sx={{
        margin:{xs:'4rem 1.3rem', sm:'4rem 1.3rem', md:'9.5rem 0rem', lg:'9.5rem 0rem', xl:'9.5rem 0rem'}, 
        padding:{xs: '0rem', sm: '0rem', md: '0rem', lg:'0rem', xl:'0rem'}}}>
            <Card 
            sx={{
            boxShadow: {xs:'0px 0px 41.6px 0px rgba(0, 170, 255, 0.25)', sm:'0px 0px 41.6px 0px rgba(0, 170, 255, 0.25)', md:'0px -36px 100px 0px rgba(0, 170, 255, 0.25)', lg: '0px -36px 100px 0px rgba(0, 170, 255, 0.25)', xl: '0px -36px 100px 0px rgba(0, 170, 255, 0.25)'},
            height:{xs:'100%', sm:'100%', md:'22rem', lg:'22rem', xl:'22rem'},
            width:{xs: '100%', sm: '100%', md: '47rem', lg: '47rem', xl: '47rem'},
            padding:{xs:'2rem', sm:'2rem' , md:'initial', lg:'initial', xl:'initial'},
            borderRadius: '1rem',}} className={classes.marketplaceWrapper}>
                <CardContent sx={{display: 'flex', flexDirection: 'column', padding: '0px', 
                paddingBottom:{xs:'0px', sm:'0px', md:'initial', lg:'initial', xl:'initial'},
                }}>
                <Grid item  margin={'auto'} sx={{
                    width: {xs:'3rem', sm:'3rem', md:'3.8rem', lg:'3.8rem', xl:'3.8rem'},
                    marginBottom:{xs:'0rem', sm:'0rem', md:'1rem', lg:'1rem', xl:'1rem'},
                    }}>
                        <img src={azureLogo} alt='Azure' width={'100%'}/>
                    </Grid>
                    <Typography color={globalColors.color_9} textAlign={'center'} style={heading} sx={{
                        fontSize:{xs:globalFontSize.font_28, sm:globalFontSize.font_28, md:globalFontSize.font_35, lg:globalFontSize.font_35, xl:globalFontSize.font_35},
                        }}>
                        <span>We are on </span><br/>
                        <span>Azure Marketplace</span>
                    </Typography>
                    <Button sx={button} href='https://azuremarketplace.microsoft.com/en-us/marketplace/apps/wipro_lab45_aiteam.wipro-ai360platform?tab=Overview' target='_blank'>
                    Check Out our Apps on Azure Marketplace
                  </Button>
                </CardContent>
            </Card>
        </Box>
    )
}

export default MarketPlace