export const proximaNovaRegularFont = {
    fontFamily: "Proxima Nova Regular",
    src: `
     url('/fonts/proximanova_regular.ttf') format('truetype'),
    `,
};

export const proximaNovaLightFont = {
    fontFamily: "ProximaNova-Light",
    src: `
     url('/fonts/proximanova_light.ttf') format('truetype'),
    `,
};

export const proximaNovaBoldFont = {
    fontFamily: "Proxima Nova Bold",
    src: `
     url('/fonts/proximanova_bold.ttf') format('truetype'),
    `,
};

export const proximaNovaExtraBoldFont = {
    fontFamily: "ProximaNova-ExtraBold",
    src: `
     url('fonts/proximanova_extrabold.otf') format('opentype'),
    `,
};

export const proximaNovaAltLightFont = {
    fontFamily: "Proxima Nova Alt Light",
    src: `
     url('fonts/Proxima Nova Alt Light.otf') format('opentype'),
    `,
};

export const proximaNovaAltBoldFont = {
    fontFamily: "Proxima Nova Alt Bold",
    src: `
     url('fonts/Proxima Nova Alt Bold.otf') format('opentype'),
    `,
};


export const globalFontSize = {
    font10: '0.66rem',
    font_12: "0.76rem",
    font_13: "0.81rem",
    font_14: "0.87rem",
    font_15: "1.0rem",
    font_15v2: "0.95rem",
    font_17:  "1.1rem",
    font_18: "1.125rem",
    font_19: "1.2rem",
    font_20: "1.3rem",
    font_24: "1.5rem",
    font_27: "1.6875rem",
    font_28: "1.8rem",
    font_30: "1.9rem",
    font_32: '2.0rem',
    font_35: "2.2rem",
    font_38: "2.3rem",
    font_40: "2.5rem",
    font_16: "0.76rem",
    font_48: "3.0rem",

};

export const globalFontWeight = {
    font_300:300,
    font_400: 400,
    font_700: 700,

}