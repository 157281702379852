import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button, Typography } from '@mui/material';
import { globalFontSize, globalFontWeight, proximaNovaRegularFont } from '../fonts';

interface PrivacyPolicyPopUpProps {
    open: boolean;
    onClose: () => void;
}
const PrivacyPolicyPopUp: React.FC<PrivacyPolicyPopUpProps> = ({ open, onClose }) => {
    const Dialogcontent = {
        fontSize:globalFontSize.font_15, 
        fontFamily:proximaNovaRegularFont.fontFamily, 
        width:'100%'
    }
    return (
        <Dialog open={open} aria-labelledby='privacy-policy-title' sx={{
            '& .MuiDialog-paper': {
                maxWidth: '85%',
            }
        }}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    onClose();
                }
            }}>
            <DialogTitle id="privacy-policy-title">Privacy Policy</DialogTitle>
            <DialogContent >
                <Typography paragraph style={Dialogcontent}>
                    Welcome to ai360! We hope that you will be able to enjoy this tool and what it offers. It has been created with privacy and security at its heart as we, Wipro, believe that privacy and innovation can go hand in hand. This notice outlines:
                    <Typography paragraph style={Dialogcontent}> - how ai360 process personal data</Typography>
                    <Typography paragraph style={Dialogcontent}> - what happens to the content of your inputs</Typography>
                </Typography>

                <Typography paragraph style={Dialogcontent} sx={{fontWeight: globalFontWeight.font_700}}>
                    Finally... a reminder: do not input any personal data in Ai360! This means any data that could directly or indirectly identify a specific person, yourself included (except for your ADID).
                    Your use of ai360 is subject at all times to the ai360 Terms of Use.
                </Typography>

                <Typography paragraph style={Dialogcontent} sx={{fontWeight: globalFontWeight.font_700}}>
                    What is ai360?
                </Typography>

                <Typography paragraph style={Dialogcontent}>
                    Ai360 is a core machine learning/artificial intelligence (ML/AI) and generative AI platform for various internal and external applications.
                    It provides users with a wide range of AI features including private chat and document chat to assist you in your everyday job.
                </Typography>

                <Typography paragraph style={Dialogcontent} sx={{fontWeight: globalFontWeight.font_700}}>
                    How does ai360 work?
                </Typography>

                <Typography paragraph style={Dialogcontent}>
                    Ai360 is powered by the following commercial large language models (LLMs): GPT 3.5/4 provided by OpenAI and Palm2 provided by Google. A Retrieval Augmented Generation (RAG) technique is utilized in the platform to improve the quality of the LLM-generated responses for our Wipro employees.
                    This means that the documents you upload during an ai360 session will be used to improve ai360’s responses to the queries you inputted during that session.
                </Typography>

                <Typography paragraph style={Dialogcontent} sx={{fontWeight: globalFontWeight.font_700}}>
                    Will OpenAI and Google retain what you type and use them to train their LLMs?
                </Typography>
                <Typography paragraph style={Dialogcontent}>None of the data you input into ai360 will be retained for training or retraining purposes by OpenAI and Google. We opted out of logging our data and monitoring. For further information please check the specific clauses:</Typography>

                <Typography paragraph style={Dialogcontent}>- Palm: “Google will not use Customer Data to train or fine-tune any AI/ML models without Customer's prior permission or instruction”, full text available <a href='https://cloud.google.com/terms/service-terms'>here</a></Typography>

                <Typography paragraph style={Dialogcontent}>- GPT3.5/4: “Training data uploaded for fine-tuning is not used to train, retrain, or improve any Microsoft or 3rd party base models”, full text available <a href='https://learn.microsoft.com/en-us/legal/cognitive-services/openai/data-privacy?context=%2Fazure%2Fai-services%2Fopenai%2Fcontext%2Fcontext'>here</a>.</Typography>

                <Typography paragraph style={Dialogcontent} sx={{fontWeight: globalFontWeight.font_700}}>
                    What personal data does ai360 process?
                </Typography>
                <Typography paragraph style={Dialogcontent}>Ai360 will be collecting your ADID to enable a Single Sign On (SSO) authentication.</Typography>

                <Typography paragraph style={Dialogcontent} sx={{fontWeight: globalFontWeight.font_700}}>How will the ADID be processed?</Typography>
                <Typography paragraph style={Dialogcontent}>Microsoft Authentication Library (MSAL) will be used for ADID integration. We use the standard SSO AzureAD mechanism (the same that is used in SaaS apps including Office 365, salesforce, workday etc.). This means that your own Azure AD is used for authentication. Wipro does NOT store these credentials.
                    If you are inactive for a brief period of time (15 minutes), you will be logged out of the system.
                    The access token will be refreshed and regenerated seamlessly every fifty minutes until you log out.
                </Typography>

                <Typography paragraph style={Dialogcontent} sx={{fontWeight: globalFontWeight.font_700}}>What about the information you upload?</Typography>
                <Typography paragraph style={Dialogcontent}>Access control lists (ACLs) are enabled at user ADID level. This means that your queries and any documents you upload into ai360 will not be viewable by others through the ai360 platform.</Typography>

                <Typography paragraph style={Dialogcontent} sx={{fontWeight: globalFontWeight.font_700}}>Are the user activities and searches stored?</Typography>
                <Typography paragraph style={Dialogcontent}>Your queries and ai360’s responses are deleted when you log out. Your documents are encrypted in transit and at rest and are stored in Wipro’s Azure Blob storage. Only the user that uploaded the documents has access to them through the ai360 platform. Ai360 will assign a dataset id to these documents and the id will be associated with your ADID. These documents will be retained in ai360 for approximately a month under the dataset id. However, you can and should delete the dataset id at any time it is no longer necessary for ai360 to retain these documents.</Typography>

                <Typography paragraph style={Dialogcontent} sx={{fontWeight: globalFontWeight.font_700}}>Are there any cookies in the ai360 app?</Typography>
                <Typography paragraph style={Dialogcontent}>Yes, strictly necessary cookies are embedded on the ai360. These cookies are essential for navigating and enabling certain functions of ai360 such as local device authentication, and image caching. These cookies cannot be disabled.</Typography>

                <Typography paragraph style={Dialogcontent} sx={{fontWeight: globalFontWeight.font_700}}>Your rights and choices</Typography>
                <Typography paragraph style={Dialogcontent}>Depending on the country in which you are located, and the data protection laws applicable to you, you may be able to exercise rights and request information about how your data is processed. Please note that the only personal data processed by Ai360 is your ADID. You may have access to the following rights, which may have important exceptions and restrictions under applicable law:

                    <Typography paragraph style={Dialogcontent}>1. Access to the personal data we process about you;</Typography>
                    <Typography paragraph style={Dialogcontent}>2. Rectification of your personal data if it is found to be outdated, inaccurate, or incomplete;</Typography>
                    <Typography paragraph style={Dialogcontent}>3. Erasure of your personal data in cases where it is no longer needed to achieve the legitimate purpose of its processing;</Typography>
                    <Typography paragraph style={Dialogcontent}>4. Restriction of processing of your personal data in limited circumstances;</Typography>
                    <Typography paragraph style={Dialogcontent}>5. Portability of your personal data so that you may receive a copy thereof or that we transmit the same to another company on your behalf.</Typography>
                    <Typography paragraph style={Dialogcontent}>6. You have the right to object to processing of personal data</Typography>
                    <Typography paragraph style={Dialogcontent}>7. You have the right to lodge a complaint with a supervisory authority</Typography>
                    <Typography paragraph style={Dialogcontent}>8. You have the right to withdraw your consent at any time with effect for the future under the individual conditions of the consent.</Typography>
                    <Typography paragraph style={Dialogcontent}>9. Wipro does not sell your personal information.</Typography>
                    <Typography paragraph style={Dialogcontent}>10. Should you choose to exercise any of your rights under the CCPA & CPRA, Wipro will not discriminate against you in any way.</Typography>
                </Typography>

                <Typography paragraph style={Dialogcontent}>If you want to know more or if you have any specific questions, we encourage you to contact the Global Data Privacy Team at <a href='mailto: data.privacy@wipro.com'>data.privacy@wipro.com</a> Any feedback on how to improve the transparency of the information we provide is welcome!</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
export default PrivacyPolicyPopUp