import React, { useState } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../../src/slick-custom.css';
import { ReactComponent as NextIcon } from '../../images/Group 136.svg';
import { ReactComponent as PrevIcon } from '../../images/Group 137.svg';
import bgBlogOne from '../../images/MicrosoftTeams-image 2.svg';
import bgBlogTwo from '../../images/Screenshot 2024-06-12 at 09.18 1.svg';
import bgDots from '../../images/dots.svg';
import { Typography, Box, Grid, CardContent, CardActions, Button, Link, LinkProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { globalColors } from '../../color';
import { proximaNovaBoldFont, proximaNovaRegularFont, globalFontSize, globalFontWeight } from '../../fonts';
import { IconButton, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';


const useStyles = makeStyles((theme) => ({
    image: {
        width: '100%',
        borderRadius: '0.5rem',
    },
    sliderMainContent: {
        maxWidth: '60rem',
        margin: 'auto',
        position: 'relative',
        display: 'flex',
        height: '20rem',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',

    },
    sliderInner: {
        left: 0,
        top: 0,
        bottom: 0,
        position: 'absolute',
        width: '100%',


    },
    partnersHeading: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
    }
}))

interface CarouselItemsProps {
    title: string,
    description: string,
    icon: string,
    iconfour: string,
    buttonText: string,
    buttonUrl: string,
    activeLink: string,

}

interface CustomArrowProps {
    className?: string;
    onClick?: () => void;
    style?: React.CSSProperties;
    currentSlide: number;
    slideCount: number;
    slidesToShow: number;
}

export const carouselItems: CarouselItemsProps[] = [
    {
        title: `<p>Dinesh Chahlia<br/> VP and Head of Engineering and Product, Wipro Lab45</p>`,
        description: 'Unveiling Lab45 AI Platform: A Journey of Innovation and Impact',
        icon: bgBlogOne,
        iconfour: bgDots,
        buttonText: 'Read more',
        buttonUrl: '/blogs',
        activeLink: "blogs",
    },
    {
        title: `<p>WIPRO</p>`,
        description: 'Wipro launches Lab45 AI platform to boost efficiency across biz functions',
        icon: bgBlogTwo,
        iconfour: bgDots,
        buttonText: 'Read more',
        buttonUrl: 'https://www.business-standard.com/companies/news/wipro-launches-lab45-ai-platform-to-boost-efficiency-across-biz-functions-124061101047_1.html',
        activeLink: "blogs",
    },

]

const Blog: React.FC = () => {
    const theme = useTheme();
    const [currentSlide, setCurrentSlide] = useState(0);
    const isMobile = useMediaQuery('(max-width:459px)');
    const PrevArrow: React.FC<CustomArrowProps> = (props) => {
        const { className, onClick, style, currentSlide } = props;
        const isDisabled = currentSlide === 0;

        console.log(`PREVARROW - currentSlide: ${currentSlide}, isDisabled: ${isDisabled}`)
        return (
            <IconButton sx=
                {{
                    display: "block", top: '36%', position: 'absolute', padding: 0,
                    width: { xs: '2rem', sm: '2rem', md: '3rem', lg: '3rem', xl: '3rem' },
                    left: { xs: '35px', sm: '-40px', md: '-70px', lg: '-70px', xl: '-70px' },
                    '&:hover': {
                        backgroundColor: 'unset'
                    }
                }}
                onClick={onClick} className={className} disabled={isDisabled}>
                {isDisabled ? <PrevIcon width="100%" /> : <NextIcon width="100%" style={{ transform: 'rotateY(180deg)' }} />}
            </IconButton>
        );
    };

    const NextArrow: React.FC<CustomArrowProps> = (props) => {
        const { className, onClick, currentSlide, style, slideCount, slidesToShow } = props;
        const isDisabled = currentSlide >= slideCount - slidesToShow;
        console.log(`Nextarrow - currentSlide: ${currentSlide}, isDisabled: ${isDisabled}`)
        return (
            <IconButton sx=
                {{
                    display: "block", top: '36%', position: 'absolute', padding: 0,
                    width: { xs: '2rem', sm: '2rem', md: '3rem', lg: '3rem', xl: '3rem' },
                    right: { xs: '30px', sm: '-30px', md: '-10px', lg: '-10px', xl: '-10px' },
                    '&:hover': {
                        backgroundColor: 'unset'
                    }
                }}
                onClick={onClick} className={className} disabled={isDisabled}
                style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}>
                {isDisabled ? <PrevIcon width="100%" style={{ transform: 'rotateY(180deg)' }} /> : <NextIcon width="100%" />}
            </IconButton>
        );
    };

    const settings = {
        dots: false,
        infinite: false,
        centerMode: false,
        variableWidth: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: false,
        nextArrow: isMobile ? <NextArrow currentSlide={currentSlide} slideCount={carouselItems.length} slidesToShow={3} /> : undefined,
        prevArrow: isMobile ? <PrevArrow currentSlide={currentSlide} slideCount={carouselItems.length} slidesToShow={3} /> : undefined,
        responsive: [
            {
                breakpoint: 459,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    swipeToSlide: true,
                    centerMode: false,
                    variableWidth: false,
                    nextArrow: <NextArrow currentSlide={currentSlide} slideCount={carouselItems.length} slidesToShow={1} />,
                    prevArrow: <PrevArrow currentSlide={currentSlide} slideCount={carouselItems.length} slidesToShow={1} />,
                }
            },

        ],
    };

    const classes = useStyles();
    const title = {
        color: globalColors.color_4,
        fontFamily: proximaNovaBoldFont.fontFamily,
        fontWeight: globalFontWeight.font_700,
        lineHeight: 'normal',
        fontSize: { xs: globalFontSize.font_28, sm: globalFontSize.font_28, md: globalFontSize.font_35, lg: globalFontSize.font_35, xl: globalFontSize.font_35 },
        paddingBottom: { xs: '0.5rem', sm: '0.5rem', md: 'unset', lg: 'unset', xl: 'unset' },
    };
    const subHeading = {
        color: globalColors.color_10,
        fontFamily: proximaNovaRegularFont.fontFamily,
        fontWeight: globalFontWeight.font_400,
        lineHeight: 'normal',
        fontSize: { xs: globalFontSize.font_19, sm: globalFontSize.font_19, md: globalFontSize.font_20, lg: globalFontSize.font_20, xl: globalFontSize.font_20 }
    };
    const usecaseOuterWrapper = {
        maxWidth: '1366px',
        flexDirection: 'column', alignItems: 'center',
        margin: { xs: '3rem 0rem', sm: '3rem 0rem', md: 'auto', lg: '8rem 10rem', xl: '8rem 10rem', xxl: '8rem auto' },
        width: { xs: 'auto', sm: 'auto', md: 'initial', lg: 'initial', xl: 'initial' },
    }

    const cardContent = {
        width: '13rem',
        marginLeft: { xs: '0rem', sm: '0rem', md: '0.8rem', lg: '0.8rem', xl: '0.8rem' },
        marginTop: { xs: '0rem', sm: '0rem', md: '2rem', lg: '2rem', xl: '2rem' },
        padding: { xs: '1.5rem 1rem', sm: '1.5rem 1rem', md: '0rem', lg: '0rem', xl: '0rem' },
    };

    const cardTitle = {
        color: globalColors.color_15,
        textTransform: 'uppercase',
        lineHeight: 'normal',
        letterSpacing: { xs: 'unset', sm: 'unset', md: '0.07rem', lg: '0.07rem', xl: '0.07rem' },
        fontFamily: 'Proxima Nova Regular',
        fontWeight: globalFontWeight.font_400,
        fontSize: { xs: globalFontSize.font_14, sm: globalFontSize.font_14, md: globalFontSize.font10, lg: globalFontSize.font10, xl: globalFontSize.font10 },
        minHeight: '40px'
    };
    const cardDescription = {
        color: globalColors.color_8,
        lineHeight: 'normal',
        minHeight: '70px',
        fontFamily: 'Proxima Nova Regular',
        fontWeight: globalFontWeight.font_400,
        fontSize: { xs: globalFontSize.font_15, sm: globalFontSize.font_15, md: globalFontSize.font_15v2, lg: globalFontSize.font_15v2, xl: globalFontSize.font_15v2 }
    };
    return (
        <>
            <Grid container id="blogs" sx={usecaseOuterWrapper}>
                <Grid sx={{
                    margin: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' },
                    paddingBottom: { xs: '2rem', sm: '2rem', md: '3rem', lg: '3rem', xl: '3rem' },
                }}
                    xs={12} md={8} item className={classes.partnersHeading} flexDirection={'column'}>
                    <Typography sx={title}>
                        <span>Blog & PR</span>
                    </Typography>
                    <Typography sx={subHeading} textAlign={'center'}>
                        <span>Check out our latest blogs & news!</span>
                    </Typography>
                </Grid>
                <Box className={classes.sliderMainContent}>
                    <Slider {...settings} className={classes.sliderInner}>
                        {carouselItems.map((item, index) => (
                            <Grid item position={'relative'}>
                                <Box sx={{
                                    position: 'absolute', top: '26px', right: '60px', width: '12rem',
                                    display: { xs: 'none', sm: 'none', md: 'inherit', lg: 'inherit', xl: 'inherit' }
                                }}>
                                    <img src={item.iconfour} alt='' className={classes.image} />
                                </Box>
                                <Grid item position={'relative'}
                                    sx={{ width: { xs: '13rem', sm: '13rem', md: '14rem', lg: '14rem', xl: '14rem' } }}>
                                    <Box>
                                        <img src={item.icon} alt="" className={classes.image} />
                                    </Box>
                                </Grid>
                                <CardContent sx={cardContent}>
                                    <Typography variant='h5' component="div" sx={cardTitle} dangerouslySetInnerHTML={{ __html: item.title }}>

                                    </Typography>
                                    <Typography variant='body2' sx={cardDescription}>
                                        {item.description}
                                    </Typography>
                                    <CardActions sx={{ padding: '0.5rem 0rem' }}>
                                        {item.buttonUrl.startsWith('http') ? (
                                            <Link href={item.buttonUrl} target="_blank" rel="noopener noreferrer">
                                                {item.buttonText}
                                            </Link>
                                        ) : (
                                            <Link component={RouterLink} to={{
                                                pathname: item.buttonUrl, state: { activeLink: item.activeLink }
                                            } as RouterLinkProps["to"]}>
                                                {item.buttonText}
                                            </Link>
                                        )}
                                    </CardActions>
                                </CardContent>
                            </Grid>
                        ))
                        }
                    </Slider>
                </Box>

            </Grid>
        </>

    )
}

export default Blog





