import React, { useEffect, useState } from 'react';
import { Link, useLocation,  useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Button, Box, IconButton, ListItem, List, useMediaQuery, useTheme, Drawer, createTheme} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';
import { globalFontWeight, proximaNovaBoldFont, proximaNovaRegularFont } from '../../fonts';
import { globalColors } from '../../color';
import { useActiveLink } from '../../ActiveLinkContext';
const logo = require("../../images/logo.svg").default;
const mobileLogo = require("../../images/mobileLogo.svg").default;


const theme = createTheme();
const useHeaderStyles = makeStyles({
    root: {
        zIndex: `${theme.zIndex.drawer + 1}`,
        overflowY:'scroll',
    },

    active:{
      color: globalColors.color_4,
      fontFamily: proximaNovaBoldFont.fontFamily,
      fontSize: '0.8rem',
      fontWeight: globalFontWeight.font_700,
      lineHeight: 'normal',
      textDecoration: 'none',
    },
    StyledLink:{
      color: globalColors.color_4,
      fontFamily: proximaNovaRegularFont.fontFamily,
      fontSize: '0.8rem',
      fontWeight: globalFontWeight.font_400,
      lineHeight: 'normal',
      textDecoration: 'none',
      '&:hover':{
        color: globalColors.color_4,
        fontWeight: globalFontWeight.font_700,
      }
    }
})

const Header: React.FC = () => {
    const buttonText = {
    color: '#fff',
    backgroundColor: globalColors.color_2,
    fontFamily: proximaNovaRegularFont.fontFamily,
    fontWeight: 700,
    borderRadius: '12.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5rem 1.0rem',
    gap: '0.625rem',
    lineHeight: 'normal',
    border: 'none',

};
 
  const navLink = {
    fontFamily: proximaNovaRegularFont.fontFamily,
    alignItems: 'center',
    gap: '2.3rem',
    fontWeight: '400'
  };
  const headerFixed = {
    display: 'flex',
    justifyContent: 'space-between',   
  }

  const mobileList = {
    alignItems: 'flex-start',
    gap: '0.625rem',
    padding: '0.625rem 1.25rem',
  }

  const mobileLink = {
    width:'100%',
    fontSize: '1.125rem',
    borderBottom: '1px solid #DEE1EE',
    padding: '0.3rem 0',
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const {activeLink, setActiveLink} = useActiveLink();
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useHeaderStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);

  const HEADER_HEIGHT = 180;

  const handleDrawerToggle = ()=>{
    setMobileOpen(!mobileOpen);
    console.log(!mobileOpen)
  }

  const scrollToSection = (sectionId:string)=>{
    const section = document.getElementById(sectionId);
      if (section) {
        const top = section.getBoundingClientRect().top + window.scrollY - HEADER_HEIGHT;
        window.scrollTo({top, behavior: "smooth"});
    }
  }

  useEffect(()=>{
    const path = location.pathname.replace("/", "") || "home";
    setActiveLink(path)

    if (location.hash && location.pathname === "/") {
      const sectionId = location.hash.replace("#", "");
      scrollToSection(sectionId);
      setActiveLink(sectionId);
    }
  }, [location, setActiveLink]);

  const handleSectionClick = (event:React.MouseEvent<HTMLAnchorElement>, section: string)=>{
    event.preventDefault();
    setActiveLink(section);
    setMobileOpen(false)
    if (section === "use-cases" && activeLink === "use-cases") {
        navigate("/all-usecases", {replace: true}); 
    
    }
    else if (location.pathname !== "/" ) {
      navigate("/", {state: {scrollTo: section}}) 
    }
    else{

      scrollToSection(section);
    }
    if (section === 'blogs' && activeLink === "blogs") {
      navigate("/blogs", {replace: true}); 
    }
  }

  
  const handleLogoClick = (event:any)=>{
    event.preventDefault();
    setActiveLink("home");
    window.scrollTo(0, 0);
    navigate("/")
  }

  // for sticky header
  useEffect(()=>{
    const handleScroll = ()=>{
      if (window.scrollY > 50) {
        setSticky(true)
      }
      else{
        setSticky(false)
      }
    }
    window.addEventListener("scroll", handleScroll);
  return ()=>{
    window.removeEventListener("scroll", handleScroll)
  }
  }, [])

  const drawer = (
    <Box>
      <Box sx={{display:'flex', justifyContent:'flex-end', p:1}}>
          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon/>
          </IconButton>
      </Box>
      <List sx={{paddingBottom:'5rem'}}>
        <ListItem sx={{flexDirection:'column'}} style={mobileList}>
        <Link
            to="/#benefits"
            onClick={(e)=>handleSectionClick(e, "benefits")}
            className={activeLink === "benefits" ? classes.active: classes.StyledLink} style={mobileLink}>
            Benefits & Features
          </Link>
        <Link
            to="/#partners"
            onClick={(e)=>handleSectionClick(e, "partners")}
            className={activeLink === "partners" ? classes.active: classes.StyledLink} style={mobileLink}>
            Partners
          </Link>

           <Link
            to="/#use-cases"
            onClick={(e)=>handleSectionClick(e, "use-cases")}
            className={activeLink === "use-cases" ? classes.active: classes.StyledLink} style={mobileLink}>
             Use cases
          </Link>

          <Link
            to="/#blogs"
            onClick={(e)=>handleSectionClick(e, "blogs")}
            className={activeLink === "blogs" ? classes.active: classes.StyledLink} style={mobileLink}>
             Blogs
          </Link>

          <Link
            to="/#faqs"
            onClick={(e)=>handleSectionClick(e, "faqs")}
            className={activeLink === "faqs" ? classes.active: classes.StyledLink} style={mobileLink}>
             FAQs
          </Link>

          <Link
            to="/#contact-us"
            onClick={(e)=>handleSectionClick(e, "contact-us")}
            className={activeLink === "contact-us" ? classes.active: classes.StyledLink} style={mobileLink}>
             Contact Us
          </Link> 
         
        </ListItem>
      </List>
      <Button variant='outlined' color="primary" sx={{ 
        display: { xs: 'flex', sm: 'flex', md:'flex', lg:'flex', xl:'flex'}, 
        textTransform:{xs:"capitalize", sm:"capitalize", md:"capitalize", lg:"capitalize"},
        fontSize: {xs:'1.125rem', sm:'1.125rem', md:'0.8rem', lg:'0.8rem', xl:'0.8rem'},
        width:{xs:'10rem', sm:'10rem', md:'auto', lg:'auto', xl:'auto'},
        margin:'auto',
      }} 
        style={buttonText} href="https://apps.lab45.ai" target='blank'>
          Log In
        </Button>  
        <Box sx={{ width: { xs: '100%', sm: 'auto', md: '15rem' }, 
        display: 'flex', justifyContent: 'center', position:'fixed', bottom:'0' }}>
          <Link to="/">
             <img src={mobileLogo} alt='logo' style={{ width: '100%' }} />
          </Link>
        </Box>    
    </Box>
  );

  const desktopMenu = (
      <>
        <Box sx={{ width: { xs: '100%', sm: 'auto', md: '15rem' }, display: 'flex', justifyContent: 'space-between'}}>
          <Link to="/" onClick={handleLogoClick}>
          <img src={logo} alt='logo' style={{ width: '100%' }} />
          </Link>
        </Box>
        <Box style={navLink} sx={{fontSize:{xs:'1rem', sm:'1rem', md:'0.8rem', lg:'0.8rem', xl:'1rem'},
         display:{ xs: 'none', sm: 'flex' },

      }}>
          <Link
            to="/#benefits"
            onClick={(e)=>handleSectionClick(e, "benefits")}
            className={activeLink === "benefits" ? classes.active: classes.StyledLink}>
            Benefits & Features
          </Link>

          <Link
            to="/#partners"
            onClick={(e)=>handleSectionClick(e, "partners")}
            className={activeLink === "partners" ? classes.active: classes.StyledLink}>
            Partners
          </Link>

           <Link
            to="/#use-cases"
            onClick={(e)=>handleSectionClick(e, "use-cases")}
            className={activeLink === "use-cases" ? classes.active: classes.StyledLink}>
             Use cases
          </Link>

          <Link
            to="/#blogs"
            onClick={(e)=>handleSectionClick(e, "blogs")}
            className={activeLink === "blogs" ? classes.active: classes.StyledLink}>
             Blogs
          </Link>

          <Link
            to="/#faqs"
            onClick={(e)=>handleSectionClick(e, "faqs")}
            className={activeLink === "faqs" ? classes.active: classes.StyledLink}>
             FAQs
          </Link>

          <Link
            to="/#contact-us"
            onClick={(e)=>handleSectionClick(e, "contact-us")}
            className={activeLink === "contact-us" ? classes.active: classes.StyledLink}>
             Contact Us
          </Link>          
        </Box>
        <Button variant='outlined' color="primary" sx={{ 
        display: { xs: 'none', sm: 'none', md:'flex', lg:'flex', xl:'flex'},
        fontSize:'0.8rem', 
        textTransform:{xs:"capitalize", sm:"capitalize", md:"capitalize", lg:"capitalize"}}} 
        style={buttonText} href="https://apps.lab45.ai" target='blank'>
          Log In
        </Button>
      </>
  );
 
  
  return (
    <AppBar position='static' sx={{maxWidth: '100%', margin: 'auto', 
    display:{xs:'block', sm:'block', md:'block', lg:'block', xl:'block'},
    height:{xs:'56px', sm:'56px', md:'88px', lg:'88px', xl:'88px'},
    position: isSticky ? "fixed" :"relative",
    top: isSticky ? 0: 'auto',
    left:0,
    width:'100%',
    boxShadow: isSticky ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "none",
    transition: 'all 0.5s ease',
    zIndex: {xs:theme.zIndex.drawer + 0, sm:theme.zIndex.drawer + 0, md:theme.zIndex.drawer + 1, lg:theme.zIndex.drawer + 1,xl:theme.zIndex.drawer + 1},
    backgroundColor: isSticky ? "#fff" : "unset"

    }}>
      <Toolbar style= {headerFixed} sx={{
        height: { xs: '2rem', sm: '2.8rem', md: '5.5rem', lg: '5.5rem', xl: '5.5rem'},
        padding: { xs: '0.5rem', sm: '1rem', md: '1.5rem 3.5rem', lg:'1.5rem 3.5rem' , xl: '1.5rem 3.5rem'},
        zIndex:{xs:'inherit', sm:'inherit', md:theme.zIndex.drawer + 1, lg:'111111', xl:'111111'},
        position:'fixed',
        top:0,
        left:0,
        right:0}}>
       
       {isMobile ? (
         <>
          <Box sx={{ width: { xs: 'auto', sm: 'auto', paddingTop:'10px'}}}>
         <Link to="/" onClick={handleLogoClick}>
             <img src={mobileLogo} alt='logo' style={{ marginLeft:'1.1rem', }} />
          </Link>
        </Box>   

          <IconButton 
          color="inherit" 
          aria-label='open drawer' 
          onClick={handleDrawerToggle}
          sx={{ml:'auto', color:'#351A55'}}
          >
           <MenuIcon/>
          </IconButton>
          <Drawer 
          anchor="left" 
          open={mobileOpen} 
          onClose={handleDrawerToggle} 
          sx={{"& .MuiDrawer-paper": {width: "100%",  
          background: '#FBFAFF', 
        }}}
          >
            {drawer}
          </Drawer>
         </>
       ): (
        desktopMenu
       )}
      </Toolbar>
    </AppBar>
  );
};
export default Header