import React from 'react';
import { makeStyles, styled } from '@mui/styles';
import { Grid, Typography, Box } from '@mui/material';
import { proximaNovaBoldFont, proximaNovaRegularFont, globalFontSize, globalFontWeight } from '../../../fonts';
import { globalColors } from '../../../color';
import dividerLine from '../../../images/divider.svg';
import {contentItems5, ContentItem} from '../ContentItems';
const useStyles = makeStyles({
    image: {
        width: '100%'
    },
});

const SupportList:React.FC = ()=>{
    const classes = useStyles();

    const CustomerTitle = {
    color: globalColors.color_4,
    fontFamily: proximaNovaBoldFont.fontFamily,
    lineHeight: 'normal',
    textAlign:{xs:'left', sm:'left', md:'center', lg:'center', xl:'center'},
    fontWeight: globalFontWeight.font_700, 
    fontSize:{xs:globalFontSize.font_28, sm:globalFontSize.font_28, md:globalFontSize.font_32, lg:globalFontSize.font_32, xl:globalFontSize.font_32},
    };

    const Description = {
        color: globalColors.color_7,
        fontFamily: proximaNovaRegularFont.fontFamily,
    };
     
    // code for divider
    const CustomDivider = styled(Box)(({theme})=>({
        position:'relative',
        '&::after':{
            content: '""',
            position:'absolute',
            top:60,
            bottom:0,
            left:0,
            right:0,
            width:'65%',
            height:'2px',
            backgroundImage: `url(${dividerLine})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize:'100%',
            margin:'auto'
        }
    }))

    const CustomDivider1 = styled(Box)(({theme})=>({
        position:'relative',
        '&::after':{
            content: '""',
            position:'absolute',
            top:'-2rem',
            bottom:0,
            left:0,
            right:0,
            width:'100%',
            height:'2px',
            backgroundImage: `url(${dividerLine})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize:'100%',
            margin:'auto',
        }
    }))
   
    return (
        <Grid container sx={{maxWidth:{xl: '1366px'}, 
        margin:{xs:'4rem 1.3rem', sm:'4rem 1.3rem', md:'auto', lg:'auto', xl:'auto'},
        marginBottom:{xs:'0rem', sm:'0rem', md:'unset', lg:'unset', xl:'unset'},
        width:{xs:'auto', sm:'auto', md:'100%', lg:'100%', xl:'100%'},
        }} >
           {contentItems5.map((item:ContentItem, index)=>(
               <Box position={'relative'} key={item.id} sx={{
               margin:{xs:'unset', sm:'unset', md:'4rem 0rem', lg:'4rem 0rem', xl:'4rem 0rem'},
               }}>
                  <Grid item xs={12} md={9} 
                  sx={{display: 'flex', alignItems: 'center', margin: 'auto', 
                  justifyContent:{xs:'left', sm:'left', md:'center', lg:'center', xl:'center'},

                  }}>
                     <Box>
                        <Typography variant='h2' 
                         sx={CustomerTitle} letterSpacing={'unset'}>{item.title}</Typography>
                     </Box>
                  </Grid>
                  <Grid item container xs={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', 
                  margin:{xs:'0rem', sm:'0rem', md:'3rem 0rem 0rem 0rem', lg:'3rem 0rem 0rem 0rem', xl:'3rem 0rem 0rem 0rem'},
                  paddingBottom:{xs:'4rem', sm:'4rem', md:'unset', lg:'unset', xl:'unset'}
                  }}>
                  <Grid item xs={12} md={9} order={index % 2 ===0 ?1:2} sx={{display: 'flex', justifyContent: 'flex-start'}}>
                  <Box>
                        <Typography variant='h2' 
                        sx={{fontWeight: globalFontWeight.font_400, letterSpacing: 'unset', 
                        fontSize:{xs:globalFontSize.font_19, sm:globalFontSize.font_19, md:globalFontSize.font_30, lg:globalFontSize.font_30, xl:globalFontSize.font_30},
                        lineHeight:{xs:'1.8rem', sm:'1.8rem', md:'3rem', lg:'3rem', xl:'3rem'},
                        }} style={Description}>{item.description}</Typography>
                     </Box>
                  </Grid>
                  </Grid>

                  {index !== contentItems5.length - 1 && 
                  <>
                  <CustomDivider sx={{display:{xs:'none', sm:'none', md:'inherit', lg:'inherit', xl:'inherit'}, } }/>
                  <CustomDivider1 sx={{display:{xs:'block', sm:'block', md:'none', lg:'none', xl:'none'}, } }/>
                  </>
                  
                  }
                  
               </Box>
            ))
           }
        </Grid>
    )
}
export default SupportList