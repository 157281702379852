import React, { useEffect, useState } from 'react';
import { IconButton, Box } from '@mui/material';
import { ReactComponent as CustomArrowUp } from '../images/CustomArrowUp.svg';
import { ReactComponent as CustomArrowUpHover } from '../images/CustomArrowHover.svg';

const ScrollToTop: React.FC = () => {
    const [showArrow, setShowArrow] = useState(false);
    const [isHovered, setHovered] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setShowArrow(true)
        }
        else {
            setShowArrow(false)
        }
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const scrollIcon = {
        display: showArrow ? 'block' : 'none',
        position: 'fixed', 
        bottom: {xs:'1rem', sm:'1rem', md:'2rem', lg:'2rem', xl:'2rem'}, 
        right: {xs:'1rem', sm:'1rem', md:'2rem', lg:'2rem', xl:'2rem'}, 
        width:{xs:'1.5rem', sm:'1.5rem', md:'initial', lg:'initial', xl:'initial'},
        zIndex: 1600,
    }

    return (
        <Box sx={scrollIcon}>
            <IconButton sx={{padding:'0px', width:'100%'}} onClick={scrollToTop}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                {isHovered ? <CustomArrowUpHover /> : <CustomArrowUp />}
            </IconButton>
        </Box>
    )
}
export default ScrollToTop